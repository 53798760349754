import riotBar from 'Common/Service/RiotBar/RiotBar'
import locale from 'Common/Locale/Locale'
import config from 'Common/Config/Config'
import serviceUtil from 'Common/Service/Util/ServiceUtil'
import Image from 'Component/Asset/Image/Image'

const dropsHosts = {
  local: '/mock-service/drops',
  dev: 'https://dev-account.service.lolesports.com/fandom-account/v1',
  test: 'https://qa-account.service.lolesports.com/fandom-account/v1',
  stage: 'https://stage-account.service.lolesports.com/fandom-account/v1',
  prod: 'https://account.service.lolesports.com/fandom-account/v1'
}

const localizationHosts = {
  local:
    'https://dev-localization.service.lolesports.com/dropLocalization/v1.0',
  dev: 'https://dev-localization.service.lolesports.com/dropLocalization/v1.0',
  test: 'https://qa-localization.service.lolesports.com/dropLocalization/v1.0',
  stage:
    'https://stage-localization.service.lolesports.com/dropLocalization/v1.0',
  prod: 'https://localization.service.lolesports.com/dropLocalization/v1.0'
}

const env = window.sessionStorage.getItem('rewards-api-env') || config.env
const dropsBaseUrl = dropsHosts[env]
const localizationBaseUrl = localizationHosts[env]
const defaultDropsLocale = 'en_US'
const maxDropSpecificRetries = 10
const dropSpecificMinWaitTimeMilliseconds = 3000
const dropSpecificWaitTimeWindowMilliseconds = 3000

const dropsOptions = {
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Cookie __Secure-access_token'
  }
}

class RewardsDrops {
  fetchLocalization (group) {
    const url = `${localizationBaseUrl}/localization/${group}`

    return serviceUtil
      .fetch(url)
      .then((json) => {
        const selectedLocale = json.find(
          (loc) => loc.localeKey === locale.get(true)
        )

        const defaultLocale = json.find(
          (loc) => loc.localeKey === defaultDropsLocale
        )

        return (
          selectedLocale && selectedLocale.localization
          || defaultLocale && defaultLocale.localization
          || ''
        )
      })
      .catch(() => '') // never throw, worst case return empty string
  }

  fetchDetails (id) {
    if (!riotBar.isLoggedIn()) {
      return Promise.reject(Error(locale.translate('message.notLoggedIn')))
    }

    const url = `${dropsBaseUrl}/earnedDrops/${id}?locale=${locale.get(true)}`

    // For the VAS bulkhead, we found the optimal configuration to be retrying requests between a 3 and 6 second window,
    // randomly distributed. This backoff will ideally serve as a naive load balancer alongside the bulkhead itself
    // while users are attempting to look at their earned drops.
    const getWaitTimeMilliseconds = () => {
      const randomMillisUpTo3Seconds = Math.floor(
        Math.random() * dropSpecificWaitTimeWindowMilliseconds
      )

      return dropSpecificMinWaitTimeMilliseconds + randomMillisUpTo3Seconds
    }

    return (
      serviceUtil
        .fetchWithRetry(
          url,
          dropsOptions,
          getWaitTimeMilliseconds,
          maxDropSpecificRetries
        )
        .then((json) => {
          if (!json) {
            throw Error(locale.translate('message.invalidResponse'))
          }

          return json
        })
        // Map the old structure to the new (if the old exists)
        // TODO Get rid of this continuation once the Rewards stack gets deployed to prod
        .then((json) => {
          if (!json.inventory) return json

          const inventory = json.inventory

          json.inventory = inventory.map((inventoryItem) => {
            if (inventoryItem.inventoryDTO) {
              inventoryItem.inventory = inventoryItem.inventoryDTO
              delete inventoryItem.inventoryDTO
            }

            return inventoryItem
          })

          return json
        })
    )
  }

  fetchList () {
    if (!riotBar.isLoggedIn()) {
      return Promise.reject(Error(locale.translate('message.notLoggedIn')))
    }

    // our mocks can't be a file and a folder at the same time,
    // we use a different name when using the mocked service
    const endpoint
      = dropsBaseUrl.indexOf('mock-service') > -1 ? 'list' : 'earnedDrops'
    const url = `${dropsBaseUrl}/${endpoint}?locale=${locale.get(
      true
    )}&site=LOLESPORTS`

    return serviceUtil.fetch(url, dropsOptions).then((json) => {
      if (!json) {
        throw Error(locale.translate('message.invalidResponse'))
      }

      return json
    })
  }

  // we load in an Image to check if the image exists because calling `fetch` on the image results in a CORS error
  fetchDoesShareableDropImageExist (triggerId, dropId) {
    return new Promise((resolve, reject) => {
      const i = new window.Image()

      i.onload = () => resolve(true)
      i.onerror = () => reject(false)
      i.src = this.getShareableDropImageUrl(triggerId, dropId)
    })
  }

  getShareableDropImageUrl (triggerId, dropId) {
    const userLocale = locale.get(false)

    let baseUrl = `https://test-static.lolesports.com`

    if (env === 'prod') baseUrl = `https://static.lolesports.com`
    return Image.resizeImage(
      `${baseUrl}/drops/lightweaver/${triggerId}/${dropId}/${userLocale}.png`,
      630,
      630
    )
  }

  getColorsForRarity (gradient) {
    const getContrastColors = (inverse = false) => ({
      highContrastColor: !inverse ? '#000000' : '#ffffff',
      overlayColor: !inverse ? '#ffffff' : '#000000'
    })

    const defaultColors = getContrastColors(false)
    const stops = []
    const splitGradient = gradient.split(',')

    // parse gradient stops from a string like the following:
    // background: radial-gradient(circle, #05259b 3%, #0034f7 60%);
    for (let i = 1; i < splitGradient.length; i++) {
      const piece = splitGradient[i]
      const [color] = piece
        .replace(')', '')
        .trim()
        .split(' ')

      stops.push(color)
    }

    if (!stops?.length) {
      return defaultColors
    }

    const color = stops[stops.length - 1]
    // default to black text if not hex color

    if (!color.startsWith('#')) {
      return defaultColors
    }

    // For reference:
    // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
    const red = parseInt(color.slice(1, 3), 16)
    const green = parseInt(color.slice(3, 5), 16)
    const blue = parseInt(color.slice(5, 7), 16)

    const contrast = red * 0.299 + green * 0.587 + blue * 0.114
    const magicContrastValue = 186

    return getContrastColors(contrast <= magicContrastValue)
  }
}

export default new RewardsDrops()
