import { datadogRum } from '@datadog/browser-rum-slim'
import config from 'Common/Config/Config'

/**
 * Initializes Datadog's Real User Monitoring (RUM) service.
 */
export const setupDatadog = () => {
  datadogRum.init({
    applicationId: '5ba08381-83c4-45e1-a690-fe5aea9478dd',
    clientToken: 'pub970783e0aed57a2992a741ee45e436b0',
    defaultPrivacyLevel: 'mask-user-input',
    env: config.env,
    service: 'lol-esports',
    sessionReplaySampleRate: 0,
    sessionSampleRate: 20,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    version: config.appVersion,
    telemetrySampleRate: 0,
    allowFallbackToLocalStorage: true
  })
}
