import { Component, Logger } from 'lib'
import device from 'Common/Device/Device'
import config from 'Common/Config/Config'

const hosts = {
  local: '/Common/Asset',
  dev: 'http://assets.lolesports.com/watch',
  test: 'http://assets.lolesports.com/watch',
  stage: 'http://assets.lolesports.com/watch',
  prod: 'http://assets.lolesports.com/watch'
}
const baseUrl = hosts[config.env]

class Image extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
  }

  static background (src, sizes) {
    const url = src.indexOf('http') === 0 ? src : baseUrl + src
    const resizedUrl = Image.resizeToDevice(url, sizes)

    return `background-image: url("${resizedUrl}")`
  }

  /**
   * Resizes an image through Akamai
   * @param {string} url - url to image you want resized
   * @param {Number} width - size in pixels you want the image width resized to
   * @param {Number} height - size in pixels you want the image height resized to
   *
   * Width and Height are optional.  If left blank, the Akamai service will
   * preserve the aspect ratio of the image.  If neither are provided this is a noop.
   */
  static resizeImage (url, width = '', height = '') {
    if (window.devicePixelRatio > 1) {
      width && (width *= 2)
      height && (height *= 2)
    }

    // properly resize the image if it is on Akamai's whitelisted domains
    // and not already resized
    if (
      (url.indexOf('akamaihd.net/') !== -1
        || url.indexOf('assets.lolesports.com/') !== -1
        || url.indexOf('static.lolesports.com/') !== -1
        || url.indexOf('cdn.leagueoflegends.com/') !== -1)
      && url.indexOf('resize=') === -1
    ) {
      const akamaiUrl
        = config.env === 'prod'
          ? 'https://am-a.akamaihd.net/image?resize='
          : 'https://am.i.leagueoflegends.com/image?resize='

      return (
        `${akamaiUrl}${width}:${height}&f=` + window.encodeURIComponent(url)
      )
    }
    // Resize Contentstack images not already resized
    else if (
      url.indexOf('images.contentstack.io') !== -1
      && url.indexOf('width=') === -1
      && url.indexOf('height=') === -1
    ) {
      return `${url}?width=${width}&height=${height}`
    }

    return url
  }

  /**
   * Resizes an image through Akamai based on the device.  Ensures max width for
   * the image is no wider than the breakpoint for small and medium devices, but
   * does not resize the image on large screens by default.  Resized widths can
   * be changed by passing sizeOverrides, which also enables an image to be
   * resized on large devices.
   *
   * @param {string} url - URL to image to resize
   * @param {object=} sizeOverrides - Optional param which contains the widths to resize
   * to for each breakpoint.  Has the shape:
   * `{ small?: number, medium?: number, large?: number }`.
   * Any values which are left blank will result in the image not being
   * resized for that breakpoint.
   *
   * Default values match media breakpoints for small and medium
   */
  static resizeToDevice (url, sizeOverrides = {}) {
    const { small, medium } = device.getMediaBreakpoints()
    const sizes = Object.assign({ small, medium }, sizeOverrides)

    return Image.resizeImage(url, sizes[device.getSize()])
  }

  parseSize (size) {
    // for instances where we specifically want to return the original sized image
    // this is originally used to prevent gifs from being resized on low-pixel-density monitors
    if (size === 'original') {
      return {}
    }

    if (!size) {
      return this.log.error('No size given', size)
    }

    const [width, height] = size.split('x')

    if (!width && !height) {
      return this.log.error('Unable to parse size', size)
    }

    return { width, height }
  }

  getSrc (src, width, height) {
    return Image.resizeImage(
      src.indexOf('/') === 0 ? baseUrl + src : src,
      width,
      height
    )
  }

  render () {
    const size = this.parseSize(this.props.size)

    if (!size || !this.props.src) {
      return this.log.warn(
        `Image src ${this.props.src} and size ${size} required`
      )
    }

    return (
      <img
        src={ this.getSrc(this.props.src, size.width, size.height) }
        class={ this.props.class }
        alt={ this.props.alt || '' }
        id={ this.props.id }
        onLoad={ this.props.onLoad }
      />
    )
  }
}

export default Image
