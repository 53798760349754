import Cookies from 'js-cookie'

export function getCookieValue (cookieName) {
  return Cookies.get(cookieName)
}

export function setCookieValue (cookieName, cookieValue, expires) {
  const attributes = expires ? { secure: true, expires } : { secure: true }

  Cookies.set(cookieName, cookieValue, attributes)
}

export function removeCookieValue (cookieName) {
  Cookies.remove(cookieName)
}
