import { Logger, store, RmsClient } from 'lib'
import config from 'Common/Config/Config'
import util from 'Common/Util/Util'
import webSessionService from 'Common/Service/WebSession/WebSession'
import riotBar from 'Common/Service/RiotBar/RiotBar'

// NOTE: read Notifications.md for more info on how caster / receivers work
const waitSeed = parseInt(Date.now() * Math.random() % 5000, 10)
const casterActiveKey = 'notifications-caster-active'
const casterInactiveTimeout = 10 * util.times.SECONDS
const casterUpdateInterval = 5 * util.times.SECONDS

const rmsClientConfig = {
  enabled: true,
  environment: config.env
}

const resourceTypes = {
  BROADCAST_STARTED: 'esports/v1/broadcast_started',
  MATCH_STARTED: 'esports/v1/match_started',
  DROP_FULFILLED: 'esports/v1/drop_fulfilled'
}

class Notifications {
  rmsSetup = false

  constructor () {
    this.log = new Logger(this.constructor.name)

    this.localStorageAvailable = util.isLocalStorageAvailable()
    this.addListeners()
  }

  getCasterLastUpdated () {
    return (
      this.localStorageAvailable && window.localStorage.getItem(casterActiveKey)
    )
  }

  setCasterLastUpdated (timestamp) {
    this.localStorageAvailable
      && window.localStorage.setItem(casterActiveKey, timestamp)
  }

  addListeners () {
    store.onChange('riotBar.authChecked', () => {
      this.log.debug('RiotBar auth check completed')
      if (riotBar.isLoggedIn() && !this.rmsSetup) {
        rmsClientConfig.webSessionHostUrl = webSessionService.getWebSessionHostUrl()
        this.setup()
      }
    })
  }

  setup () {
    this.rmsSetup = true
    this.rmsClient = null
    this.broadcastChannel = new window.BroadcastChannel('notifications')

    // set this window as caster if none is casting otherwise set as a receiver
    !this.isCasting() ? this.setAsCaster() : this.setAsReceiver()
  }

  get resourceTypes () {
    return resourceTypes
  }

  setAsReceiver () {
    this.log.info('This window is set as a RECEIVER')

    window.addEventListener('storage', ({ key, newValue }) => {
      if (key === casterActiveKey && Number(newValue) === 0) {
        window.setTimeout(
          () => !this.isCasting() && this.setAsCaster(),
          waitSeed
        )
      }
    })

    window.setInterval(() => {
      // keep checking if is casting, otherwise become the caster
      !this.isCasting() && this.setAsCaster()
    }, casterInactiveTimeout + waitSeed)
  }

  isCasting () {
    const timestamp = this.getCasterLastUpdated() || 0

    return Number(timestamp) + casterInactiveTimeout > Date.now()
  }

  setAsCaster () {
    this.log.info('This window is set as CASTER')

    this.setCasterLastUpdated(Date.now())

    // keep updating the timestamp
    window.setInterval(() => {
      this.setCasterLastUpdated(Date.now())
    }, casterUpdateInterval)

    window.addEventListener('unload', () => {
      this.setCasterLastUpdated(0)
    })

    this.connect()
  }

  connect () {
    this.rmsClient = new RmsClient(rmsClientConfig)

    this.addRmsClientListeners()
    this.rmsClient.start()
  }

  addRmsClientListeners () {
    this.rmsClient.addMessageListener((data) => {
      try {
        this.message(this.parseMessage(data))

        // TODO: receive message here makes sense to guarantee only one
      }
      catch (error) {
        this.log.debug(error.message, data)
      }
    })

    this.rmsClient.addStateListener((state) => {
      this.log.debug('RMS client state is now', state)
    })
  }

  parseMessage (data) {
    const hasValidProps = data && data.payload && data.resource

    if (!hasValidProps) throw Error('Invalid properties')

    const isAllowedResource = Object.keys(resourceTypes).find(
      (key) => resourceTypes[key] === data.resource
    )

    if (!isAllowedResource) throw Error('Resource not allowed')

    const payload = JSON.parse(data.payload)

    if (payload.message) {
      // we copy the resource inside of the message as it is used to identify
      // the type of message we want to render
      payload.message.resource = data.resource
    }
    else {
      throw Error('Missing required field "message"')
    }

    return payload.message
  }

  message (message) {
    this.log.debug('Received message from RMS agent', message)
    this.broadcastChannel.postMessage(message)
  }
}

export default new Notifications()
