import { Logger, $ } from 'lib'

const mediaBreakpoints = {
  small: 720,
  mediumStart: 721,
  medium: 1075,
  large: 1076
}

const smallMediaQuery = `(max-width: ${mediaBreakpoints.small}px)`
const mediumMediaQuery
  = `(min-width: ${mediaBreakpoints.mediumStart}px) and `
  + `(max-width: ${mediaBreakpoints.medium}px)`
const largeMediaQuery = `(min-width: ${mediaBreakpoints.large}px)`

class Device {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.size = undefined
    this.setup()
  }

  getMediaBreakpoints () {
    return mediaBreakpoints
  }

  setup () {
    const small = window.matchMedia(smallMediaQuery)
    const medium = window.matchMedia(mediumMediaQuery)
    const large = window.matchMedia(largeMediaQuery)

    this.setInitialSize(small, medium, large)
    this.addMediaQueryListeners(small, medium, large)
  }

  // swipeSizes are how much a finger travels to be considered a swipe
  addSwipeListener (element, callback, swipeSizeX = 60, swipeSizeY = 60) {
    let touchStartX = 0

    let touchStartY = 0

    $(element).on('touchstart', (event) => {
      if (!event.touches) return // Not supported by all browsers

      touchStartX = event.touches[0].clientX
      touchStartY = event.touches[0].clientY
    })

    $(element).on('touchend', (event) => {
      if (!event.changedTouches) return // Not supported by all browsers

      const movedX = event.changedTouches[0].clientX - touchStartX
      const movedY = event.changedTouches[0].clientY - touchStartY

      if (movedY > swipeSizeY && movedX < -swipeSizeX) {
        callback({ topRightToBottomLeft: true })
      }

      if (movedX > swipeSizeX) {
        callback({ leftToRight: true })
      }
      else if (movedX < -swipeSizeX) {
        callback({ rightToLeft: true })
      }
      else if (movedY > swipeSizeY) {
        callback({ topToBottom: true })
      }
      else if (movedY < -swipeSizeY) {
        callback({ bottomToTop: true })
      }
    })
  }

  removeSwipeListener (element) {
    $(element)
      .off('touchstart')
      .off('touchend')
  }

  setSize (size) {
    this.size = size
    $(document).trigger('mediaSizeChange', size)
    $('body')
      .removeClass('small medium large')
      .addClass(size)
    this.log.info('Device size is', size)
  }

  getSize () {
    return this.size
  }

  setInitialSize (small, medium, large) {
    small.matches && this.setSize('small')
    medium.matches && this.setSize('medium')
    large.matches && this.setSize('large')
  }

  addMediaQueryListeners (small, medium, large) {
    small.addListener((query) => query.matches && this.setSize('small'))
    medium.addListener((query) => query.matches && this.setSize('medium'))
    large.addListener((query) => query.matches && this.setSize('large'))
  }
}

export default new Device()
