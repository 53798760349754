import { store, Refetcher, Logger } from 'lib'
import config from 'Common/Config/Config'
import locale from 'Common/Locale/Locale'
import serviceUtil from 'Common/Service/Util/ServiceUtil'
import util from 'Common/Util/Util'

const liveFetchInterval = 1 * util.times.MINUTE
// TODO: possibly increase notLive interval to 5m
const notLiveFetchInterval = 2 * util.times.MINUTES

const hosts = {
  local: '/mock-service/relapi',
  dev: 'https://dev-esports-api.lolesports.com/persisted/gw',
  test: 'https://test-esports-api.lolesports.com/persisted/gw',
  stage: 'https://esports-api.lolesports.com/persisted/gw',
  prod: 'https://esports-api.lolesports.com/persisted/gw'
}

const publicKeys = {
  local: 'none',
  dev: 'dupPZVSmYb8JYx08lOMJD4wZz0ufSKKV3iKQsiHL',
  test: '4ltanWiLim6rhurQKuKvO6qZTKMUrvpCavsXdh4o',
  stage: '0TvQnueqKa5mxJntVWt0w4LpLfEkrV1Ta8rQBb9Z',
  prod: '0TvQnueqKa5mxJntVWt0w4LpLfEkrV1Ta8rQBb9Z'
}

const baseUrl = hosts[config.env]
const headers = { headers: { 'x-api-key': publicKeys[config.env] } }

export const leaguePriority = {
  HIDDEN: 'hidden',
  SELECTED: 'selected',
  NOT_SELECTED: 'not_selected',
  FORCE_SELECTED: 'force_selected'
}

export const leagueStatus = {
  force_selected: 0,
  selected: 1,
  not_selected: 2,
  hidden: 3
}

export const selectedPriorities = [
  leaguePriority.SELECTED,
  leaguePriority.FORCE_SELECTED
]

export const sortLeaguesByPriority = (leagueA, leagueB) => {
  const leagueStatusA = leagueStatus[leagueA.displayPriority?.status]
  const leagueStatusB = leagueStatus[leagueB.displayPriority?.status]
  const leaguePosA = leagueA.displayPriority?.position
  const leaguePosB = leagueB.displayPriority?.position

  // Sort by position if status are the same
  if (leagueStatusA === leagueStatusB) {
    return leaguePosA - leaguePosB
  }

  // Default sort by status
  return leagueStatusA - leagueStatusB
}

class RelApi {
  constructor () {
    this.log = new Logger(this.constructor.name)
    this.hl = locale.get()
    this.refetchLiveEvents()
  }

  fetchLeagues () {
    // right side of the schedule page with list of leagues
    const url = baseUrl + '/getLeagues?hl=' + this.hl

    return serviceUtil.fetch(url, headers).then((json) => {
      if (!util.getDeepValue(json, 'data.leagues.length')) {
        // without leagues, there is nothing we can do. We consider this an invalid response
        throw Error(locale.translate('message.invalidResponse'))
      }
      return this.filterNonHiddenLeagues(json.data)
    })
  }

  filterNonHiddenLeagues (leagueResponse) {
    leagueResponse.leagues = leagueResponse.leagues.filter(
      (league) => league.displayPriority?.status !== leaguePriority.HIDDEN
    )
    return leagueResponse
  }

  fetchTeams () {
    const url = baseUrl + '/getTeams?hl=' + this.hl

    return serviceUtil.fetch(url, headers).then((json) => {
      if (!util.getDeepValue(json, 'data.teams.length')) {
        // without leagues, there is nothing we can do. We consider this an invalid response
        throw Error(locale.translate('message.invalidResponse'))
      }
      return json.data
    })
  }

  fetchSchedule (selectedLeagues, token) {
    // main schedule events
    const url
      = baseUrl
      + '/getSchedule?hl='
      + this.hl
      + '&leagueId='
      + serviceUtil.toGraphQLParam(selectedLeagues)
      + (token ? '&pageToken=' + token : '')

    return serviceUtil.fetch(url, headers).then((json) => {
      // if no json - this is an error.
      if (!json) throw Error(locale.translate('message.invalidResponse'))
      if (!util.getDeepValue(json, 'data.schedule.events')) {
        // current selected league(s) have no scheduled events
        return { schedule: { events: [] } }
      }
      return json.data
    })
  }

  sortByStartDate (date1, date2) {
    if (date1.startDate < date2.startDate) return 1
    else if (date1.startDate > date2.startDate) return -1
    else return 0
  }

  sortByStartTime (time1, time2) {
    if (time1.startTime < time2.startTime) return 1
    else if (time1.startTime > time2.startTime) return -1
    else return 0
  }

  fetchTournaments (leagueIds) {
    const url
      = baseUrl
      + '/getTournamentsForLeague?hl='
      + this.hl
      + '&leagueId='
      + leagueIds

    return serviceUtil.fetch(url, headers).then((json) => {
      // Our json must have a leagues array
      if (!util.getDeepValue(json, 'data.leagues.0.tournaments')) {
        // Without tournaments, there is nothing we can do. We consider this an invalid response
        throw Error(locale.translate('message.invalidResponse'))
      }

      // Return tournaments for this league sorted by start date from newest to oldest
      const leagues = json.data.leagues

      leagues.map((league) => {
        league.tournaments = league.tournaments.sort(this.sortByStartDate)
      })

      return leagues
    })
  }

  fetchVods (tournamentId) {
    const url
      = baseUrl + '/getVods?hl=' + this.hl + '&tournamentId=' + tournamentId

    return serviceUtil.fetch(url, headers).then((json) => {
      if (!util.getDeepValue(json, 'data.schedule.events')) {
        // without schedule, there is nothing we can do. We consider this an invalid response
        throw Error(locale.translate('message.invalidResponse'))
      }

      return {
        events: json.data.schedule.events.sort(this.sortByStartTime),
        nextUnstartedMatch: json.data.nextUnstartedMatch.events
      }
    })
  }

  fetchVodsForHome (leagueId) {
    const url
      = baseUrl + '/getVodsForHome?hl=' + this.hl + '&leagueId=' + leagueId

    return serviceUtil.fetch(url, headers).then((json) => {
      if (!util.getDeepValue(json, 'data.schedule.events')) {
        // without schedule, there is nothing we can do. We consider this an invalid response
        throw Error(locale.translate('message.invalidResponse'))
      }

      return json.data.schedule.events.reverse()
    })
  }

  fetchStandings (tournamentId) {
    const url
      = baseUrl + '/getStandings?hl=' + this.hl + '&tournamentId=' + tournamentId

    return serviceUtil.fetch(url, headers).then((json) => {
      if (!util.getDeepValue(json, 'data.standings.0.stages')) {
        // without stages, there is nothing we can do. We consider this an invalid response
        throw Error(locale.translate('message.invalidResponse'))
      }
      return json.data.standings[0].stages
    })
  }

  fetchStandingsV3 (tournamentId) {
    const url
      = baseUrl
      + '/getStandingsV3?hl='
      + this.hl
      + '&tournamentId='
      + tournamentId

    return serviceUtil.fetch(url, headers).then((json) => {
      if (!util.getDeepValue(json, 'data.standings.0.stages')) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return json.data.standings[0].stages
    })
  }

  hasLiveEventsChanged (currentEvents) {
    const previousEvents = store.get('liveEvents') || {}

    previousEvents.updated = undefined
    currentEvents.updated = undefined
    return JSON.stringify(previousEvents) !== JSON.stringify(currentEvents)
  }

  refetchLiveEvents () {
    // This fetches independent of containers and components the event
    // propagates out to anything that listens "liveEvents" stored value

    let isLive = true // start assuming we are live to refetch earlier

    return new Refetcher({
      url: baseUrl + '/getLive?hl=' + this.hl,
      validate: (json) => {
        if (!util.getDeepValue(json, 'data.schedule')) {
          return Error('Invalid live events')
        }
      },
      // refetch in shorter intervals during live events
      fetchInterval: () => isLive ? liveFetchInterval : notLiveFetchInterval,
      errorInterval: 2 * util.times.MINUTE,
      fetchOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': publicKeys[config.env]
        }
      }
    })
      .onFetch((json) => {
        isLive = !!util.getDeepValue(json, 'data.schedule.events.length')

        const prioritizedEvents = json.data.schedule.events.sort(
          (eventA, eventB) =>
            sortLeaguesByPriority(eventA.league, eventB.league)
        )

        const updatedSchedule = {
          updated: json.data.schedule.updated,
          events: prioritizedEvents
        }

        this.hasLiveEventsChanged(updatedSchedule)
          && store.set('liveEvents', updatedSchedule)
      })
      .onError((error) => {
        this.log.error(error)
      })
      .start()
  }

  fetchEventDetails (eventId) {
    const url = baseUrl + '/getEventDetails?hl=' + this.hl + '&id=' + eventId

    return serviceUtil.fetch(url, headers).then((json) => {
      if (json && json.data && json.data.event === null) {
        const eventFromLive = store
          .get('liveEvents')
          ?.events?.find((event) => event.id === eventId)

        if (eventFromLive?.streams?.length) {
          // use details from already fetched live events
          this.log.info('Returning event details from live for id:', eventId)
          return eventFromLive
        }
        throw Error('Event details are null for ' + url)
      }
      if (!util.getDeepValue(json, 'data.event')) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return json.data.event
    })
  }

  fetchEventList ({ teamSlug, leagueIds }) {
    let url = baseUrl + '/getEventList?hl=' + this.hl

    if (teamSlug) url += '&teamId=' + teamSlug

    if (leagueIds) url += '&leagueId=' + leagueIds

    return serviceUtil
      .fetch(url, headers)
      .then((json) => json.data.esports.events)
  }

  fetchTeamBySlug (slug) {
    const url = baseUrl + `/getTeams?hl=${this.hl}&id=${slug}`

    return serviceUtil.fetch(url, headers).then((json) => {
      const data = util.getDeepValue(json, 'data.teams')

      if (!data || data.length === 0) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return json.data.teams[0]
    })
  }

  fetchGame (id) {
    const url = baseUrl + `/getGames?hl=${this.hl}&id=${id}`

    return serviceUtil.fetch(url, headers).then((json) => {
      const data = util.getDeepValue(json, 'data.games.0')

      if (!data) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return data
    })
  }
}

export default new RelApi()
