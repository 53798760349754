/* eslint max-lines: 0 */
import { Logger } from 'lib'

let currentLocale
const pluralRegExp = /{.*}/
const localeStrings = window._localeStrings

/*
When updating this list, also update:
- /src/index.html
- /src/Component/Inform/InformRegionlessAccount
- /src/Component/Navigation/Footer
*/
const localeToLanguage = {
  'en-US': 'English (North America)',
  'en-GB': 'English (Europe)',
  'en-AU': 'English (Oceania)',
  'cs-CZ': 'Čeština',
  'de-DE': 'Deutsch',
  'el-GR': 'Ελληνικά',
  'es-ES': 'Español (España)',
  // 'es-AR': 'Español (Latinoamérica)', // es-MX is used for all LATAM
  'es-MX': 'Español (Latinoamérica)',
  'fr-FR': 'Français',
  'hu-HU': 'Magyar',
  'it-IT': 'Italiano',
  'pl-PL': 'Polski',
  'pt-BR': 'Português (Brasil)',
  'ro-RO': 'Română',
  'ru-RU': 'Русский',
  'tr-TR': 'Türkçe',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'zh-TW': '繁體中文',
  'th-TH': 'ภาษาไทย',
  'en-PH': 'English (Philippines)',
  'en-SG': 'English (Singapore)',
  'id-ID': 'Indonesian',
  'vi-VN': 'Tiếng Việt'
}

const localesWithHomepage = [
  'en-US',
  'en-GB',
  'de-DE',
  'es-ES',
  'es-MX',
  'fr-FR',
  'it-IT',
  'pl-PL',
  'pt-BR',
  'ru-RU',
  'tr-TR',
  'ja-JP',
  'ko-KR',
  'zh-TW',
  'th-TH',
  'en-SG',
  'en-PH'
]

const euLocales = [
  'cs-CZ',
  'de-DE',
  'el-GR',
  'en-GB',
  'en-PL',
  'es-ES',
  'fr-FR',
  'hu-HU',
  'it-IT',
  'pl-PL',
  'ro-RO',
  'ru-RU'
]

const localesWithSpotify = [
  'fr-FR',
  'es-ES',
  'es-MX',
  'it-IT',
  'pl-PL',
  'tr-TR',
  'de-DE',
  'en-AU',
  'en-GB',
  'en-PH',
  'en-SG',
  'en-US',
  'pt-BR',
  'th-TH',
  'ja-JP'
]

const localesWithSpotifyWidget = ['en-US', 'en-GB']

class Locale {
  constructor () {
    currentLocale = this.get()
    this.log = new Logger(this.constructor.name)
    this.log.info('Locale is', currentLocale)
    this.setFormatters()
  }

  get (underscore = false, lowercase = false) {
    let localeString
      = window.localStorage.getItem('selected-locale')
      || window.localStorage.getItem('suggested-locale')
      || 'en-US'

    if (underscore) localeString = localeString.replace('-', '_')
    if (lowercase) localeString = localeString.toLowerCase()

    return localeString
  }

  getMap () {
    return localeToLanguage
  }

  getParts (locale = currentLocale) {
    const [language, country] = locale.split('-')

    return { language, country }
  }

  set (locale) {
    window.localStorage.setItem('selected-locale', locale)
    currentLocale = locale
  }

  shortNumberFormatter (num) {
    const billion = 1000000000
    const million = 1000000
    const thousand = 1000

    if (num >= billion) {
      return `${(num / billion).toFixed(1)} ${this.translate('number.b')}`
    }
    else if (num >= million) {
      return `${(num / million).toFixed(1)} ${this.translate('number.m')}`
    }
    else if (num >= thousand) {
      return `${(num / thousand).toFixed(1)} ${this.translate('number.k')}`
    }
    else {
      return num
    }
  }

  setFormatters () {
    // pre compile formatters to be used later
    this.numberFormatter = new Intl.NumberFormat(currentLocale)

    this.dateFormatter = {
      hourAndMinute: new Intl.DateTimeFormat(currentLocale, {
        hour: 'numeric',
        minute: '2-digit'
      }),
      dayAndMonth: new Intl.DateTimeFormat(currentLocale, {
        month: 'long',
        day: 'numeric'
      }),
      shortDayAndMonth: new Intl.DateTimeFormat(currentLocale, {
        month: 'short',
        day: 'numeric'
      }),
      weekday: new Intl.DateTimeFormat(currentLocale, {
        weekday: 'long'
      }),
      dayMonthAndYear: new Intl.DateTimeFormat(currentLocale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  }

  getRelativeWeekday (timestamp, isPast) {
    // return the localized relative day if it is yesterday, earlier today, today or tomorrow. Otherwise return false
    const day = 24 * 60 * 60 * 1000 // this should work even considering leap seconds: https://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.1
    const today = new Date().setHours(0, 0, 0, 0) // set h, m, s, and ms to the start of the day
    const date = new Date(Number(timestamp)).setHours(0, 0, 0, 0)

    if (today === date) {
      // we might have games with startTime at 1AM and it is now 1:01AM
      // and the game has not started yet. This would
      // move the game to the "earlier today" header since the startTime is now
      // in the past. We pass isPast to make sure we only display the header
      // for past events
      if (isPast && timestamp < Date.now()) {
        return this.translate('date.earlierToday')
      }
      return this.translate('date.today')
    }
    else if (today - day === date) {
      return this.translate('date.yesterday')
    }
    else if (today + day === date) {
      return this.translate('date.tomorrow')
    }
    else {
      return false
    }
  }

  number (number) {
    return this.numberFormatter.format(number)
  }

  date (timestamp, isPast) {
    const ts = Number(timestamp)
    const weekday = this.dateFormatter.weekday.format(ts)
    const dayAndMonth = this.dateFormatter.dayAndMonth.format(ts)
    const shortDayAndMonth = this.dateFormatter.shortDayAndMonth.format(ts)
    const hourAndMinute = this.dateFormatter.hourAndMinute.format(ts)
    const dayMonthAndYear = this.dateFormatter.dayMonthAndYear.format(ts)

    return {
      weekday,
      dayAndMonth,
      shortDayAndMonth,
      hourAndMinute,
      dayMonthAndYear,
      relativeWeekday: this.getRelativeWeekday(ts, isPast) || weekday
    }
  }

  dateRangeException (startDate, endDate) {
    const start = this.dateFormatter.shortDayAndMonth.format(startDate)
    const end = this.dateFormatter.shortDayAndMonth.format(endDate)
    const rangeSeparator = {
      'vi-VN': '-',
      'ja-JP': '～', // U+ff5e or full width tilde
      'zh-TW': '至'
    }

    return `${start} ${rangeSeparator[currentLocale]} ${end}`
  }

  dateRange (dates) {
    const startDate = Number(dates[0])
    const endDate = Number(dates[1])

    // Some locales have inconsistent date range format in "short" month
    let isExceptionDateRange = false
    const dateRangeWithExecption = ['vi-VN', 'ja-JP', 'zh-TW']

    if (dateRangeWithExecption.indexOf(currentLocale) > -1) {
      isExceptionDateRange = true
    }

    return {
      shortDayAndMonth: isExceptionDateRange
        ? this.dateRangeException(startDate, endDate)
        : this.dateFormatter.shortDayAndMonth.formatRange(startDate, endDate)
    }
  }

  plural (str, data) {
    try {
      // get the key from the ICU string `{key, plural, {...}}`
      const key = str.split('{')[1].split(',')[0]
      const value = data[key.trim()] // clear and get value from data.key

      if (value === undefined) {
        // no value to replace, stop and return ''
        this.log.warn('Data does not contain required translation key', key)
        return ''
      }

      // if value matches a cardinal plural `=1{one thing}`, us it.
      // Otherwise use `other{more things}`
      const token
        = typeof value === 'number' && str.indexOf('=' + value) !== -1
          ? '=' + value
          : '=#'

      // get the translated content `=1{# translated content}`
      const pluralMatch = str.split(token + '{')[1].split('}')[0]

      // replace the number "#" if it exists inside the
      // content `{1 translated content}`
      const pluralMatchWithVar = pluralMatch.replace('#', value)

      // put it all back into the original translation string
      return str.replace(pluralRegExp, pluralMatchWithVar)
    }
    catch (e) {
      this.log.warn('Unable to translate', str, 'with data', data, e.message)
      return ''
    }
  }

  replaceVars (str, data) {
    const ZWNJ = '\u200C' // zero width non-joiner character

    let hasJsx = false

    for (const key in data) {
      const tplKey = '{' + key + '}'
      const isObject = typeof data[key] === 'object'

      if (isObject) hasJsx = true

      str = isObject
        ? str.split(tplKey).join(ZWNJ + key + ZWNJ)
        : str.split(tplKey).join(data[key])
    }

    // after going through all `data`, if we still have keys to replace,
    // it means we've passed wrong or incomplete `data`
    if (str.indexOf('{') > -1) {
      this.log.warn('Data', data, 'does not match required keys for', str)
      return ''
    }

    // If we have JSX mixed with the strings, return an array
    // containing ['beforeText', <jsx>stuff</jsx>, 'afterText']
    return hasJsx
      ? str
        .split(ZWNJ)
        .map((item) => typeof data[item] === 'object' ? data[item] : item)
      : str
  }

  isEuropean () {
    return euLocales.indexOf(currentLocale) > -1
  }

  supportsSpotify () {
    return localesWithSpotify.indexOf(currentLocale) > -1
  }

  getLanguageFromMediaLocale (mediaLocale) {
    // Need 2 character code for d isplaying on Front End
    const code = this.getParts(mediaLocale.locale).language
    const language = mediaLocale.translatedName

    if (language) {
      return { code, language }
    }
    else {
      this.log.warn('Missing language translation for', code)
      return { code, language: code } // still return something that works
    }
  }

  message (str, data) {
    if (!data) {
      return str
    }
    else if (str.indexOf('plural') > -1) {
      return this.plural(str, data)
    }
    else {
      return this.replaceVars(str, data)
    }
  }

  string (key, data) {
    if (localeStrings[key]) {
      return this.message(localeStrings[key], data)
    }
    else {
      this.log.warn('Missing translation for key', key)
      return '' // Don't break stuff, just return empty string on prod
    }
  }

  translate (key, data) {
    if (typeof key === 'string') {
      return this.string(key, data)
    }
    else if (typeof key === 'number') {
      return key.toString().length === 13
        ? this.date(key, data)
        : this.number(key)
    }
    else if (Array.isArray(key)) {
      return this.dateRange(key)
    }
    else {
      return ''
    }
  }

  getGeoInfo () {
    // window._geoinfo is initialized on main index.html file in
    // location.js file distributed by the Geolambda
    // geoInfo = { code: 'US', area: 'NA', locale?: 'en-US' }
    return window._geoinfo || {}
  }

  getArea () {
    const area = this.getGeoInfo().area

    if (area === 'EU' || this.isEuropean()) {
      return 'eu'
    }
    else if (area === 'NA' || currentLocale === 'en-US') {
      return 'na'
    }
    else {
      return 'row'
    }
  }

  supportsHomepage () {
    return localesWithHomepage.indexOf(this.get()) !== -1
  }

  supportsHomeSpotifyWidget () {
    return localesWithSpotifyWidget.indexOf(this.get()) !== -1
  }
}

export default new Locale()
