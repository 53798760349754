import { Logger, Refetcher, store } from 'lib'
import util from 'Common/Util/Util'
import config from 'Common/Config/Config'
import serviceUtil from 'Common/Service/Util/ServiceUtil'
import locale from 'Common/Locale/Locale'

const hosts = {
  local: '/mock-service/livestats/v1',
  dev: 'https://dev-feed.lolesports.com/livestats/v1',
  test: 'https://test-feed.lolesports.com/livestats/v1',
  stage: 'https://feed.lolesports.com/livestats/v1',
  prod: 'https://feed.lolesports.com/livestats/v1'
}

const liveStatsApiEnv = window.sessionStorage.getItem('livestats-api-env')
const liveStatsEnv = liveStatsApiEnv || config.env
const baseUrl = hosts[liveStatsEnv]

const refetchInterval = 10 * util.times.SECONDS

// Refetch from stats until we have a start time
const initialRefetchInterval = 15 * util.times.SECONDS
const errorInitialRefetchInterval = 30 * util.times.SECONDS

// file name is LiveGameEvents to get around adblockers
class LiveStats {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  getRoundedTimestamp (timestamp) {
    return Math.floor(timestamp / refetchInterval) * refetchInterval
  }

  formatTimestamp (timestamp) {
    return util.toISOString(this.getRoundedTimestamp(timestamp))
  }

  getVideoPlayerTimestamp () {
    return store.get('videoPlayer.timestamp')
      ? this.formatTimestamp(store.get('videoPlayer.timestamp'))
      : 0
  }

  getRefetcherStartCondition () {
    return (
      store.get('videoPlayer.timestamp') !== undefined
      && store.get('watch.initialTimestamp') !== undefined
    )
  }

  validateWindow (json, statusCode) {
    if (statusCode === 204) return true

    if (json && json.gameMetadata && json.frames && json.frames.length) {
      return true
    }

    return Error('Invalid stats window')
  }

  validateDetails (json, statusCode) {
    if (statusCode === 204) return true
    if (json && json.frames) return true
    return Error('Invalid stats details')
  }

  getErrorInterval (errors) {
    if (errors <= 3) return 5 * util.times.SECONDS

    const ceiling = 60 * util.times.SECONDS
    const backoff = Math.pow(errors, 2) * util.times.SECONDS

    return backoff < ceiling ? backoff : ceiling
  }

  // For VODs - we always have an initial timestamp for VOD
  // no need to use a refetcher
  fetchInitialTimestamp (gameId) {
    const url = baseUrl + '/window/' + gameId

    return serviceUtil
      .fetch(url)
      .then((json) => {
        if (!json.gameMetadata || !json.frames.length) {
          // without any events, there is nothing we can do. We consider this an invalid response
          throw Error(locale.translate('message.invalidResponse'))
        }

        return json.frames
      })
      .catch((error) => {
        throw error
      })
  }

  refetchInitialTimestamp (gameId) {
    return new Refetcher({
      url: () => baseUrl + '/window/' + gameId,

      validate: (json, statusCode) => this.validateWindow(json, statusCode),

      fetchInterval: initialRefetchInterval,
      errorInterval: errorInitialRefetchInterval
    })
  }

  getRefetchInterval () {
    const timestamp = store.get('videoPlayer.timestamp')
    const timestampDifference
      = Math.floor(timestamp / util.times.SECONDS)
      % (refetchInterval / util.times.SECONDS)

    return refetchInterval - timestampDifference * util.times.SECONDS
  }

  refetchWindow (gameId) {
    return new Refetcher({
      url: () =>
        baseUrl
        + '/window/'
        + gameId
        + '?startingTime='
        + this.getVideoPlayerTimestamp(),

      startCondition: () => this.getRefetcherStartCondition(),

      validate: (json, statusCode) => this.validateWindow(json, statusCode),

      fetchInterval: () => this.getRefetchInterval(),
      errorInterval: (tracker) => this.getErrorInterval(tracker.counter.error)
    })
  }

  refetchDetails (gameId, participants) {
    return new Refetcher({
      url: () =>
        baseUrl
        + '/details/'
        + gameId
        + '?startingTime='
        + this.getVideoPlayerTimestamp()
        + '&participantIds='
        + participants,

      startCondition: () => this.getRefetcherStartCondition(),

      validate: (json, statusCode) => this.validateDetails(json, statusCode),

      fetchInterval: () => this.getRefetchInterval(),
      errorInterval: (tracker) => this.getErrorInterval(tracker.counter.error)
    })
  }
}

export default new LiveStats()
