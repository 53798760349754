import { $, store, Logger, router } from 'lib'
import util from 'Common/Util/Util'
import locale from 'Common/Locale/Locale'
import config from 'Common/Config/Config'
import analytics from 'Common/Service/Analytics/Analytics'

const loc = locale.get(true)
const locStr = locale.get()

const hosts = {
  local: `https://cdn.rgpub.io/private/test/riotbar/latest/lolesports.${loc}.js`,
  dev: `https://cdn.rgpub.io/private/test/riotbar/latest/lolesports.${loc}.js`,
  test: `https://cdn.rgpub.io/private/test/riotbar/latest/lolesports.${loc}.js`,
  stage: `https://cdn.rgpub.io/private/test/riotbar/latest/lolesports.${loc}.js`, // TODO: this should point to prod after drops is released
  prod: `https://cdn.rgpub.io/public/live/riotbar/latest/lolesports.${loc}.js`
}

// TODO: remove after drops is released
const env = window.sessionStorage.getItem('rewards-api-env') || config.env
const regionCookieKey = 'PVPNET_REGION'
const xssoIdHintCookieKey = '__Secure-id_hint'

class RiotBar {
  constructor () {
    this.log = new Logger(this.constructor.name)

    this.addListeners()
  }

  addListeners () {
    document.addEventListener('riotbar_onshowcomplete', () => {
      this.log.info('Riotbar loaded!')

      this.loaded()
    })

    document.addEventListener('riotbar_authcheckcomplete', () => {
      this.log.info('Riotbar auth check complete!')

      this.authChecked()
    })
  }

  authChecked () {
    store.set('riotBar.authChecked', true)
    if (this.isLoggedIn()) {
      const authState = this.getAuthState()

      analytics.identify(this.getPuuid(), {
        name: authState.name,
        tag: authState.tag,
        // Note: region will be empty for regionless accounts
        region: authState.region
      })
    }
  }

  loaded () {
    // on load we want to inject our own handlers for login and logout as early as possible
    window.RiotBar.account.login = (redirectUrl) => this.login(redirectUrl)
    window.RiotBar.account.logout = (redirectUrl) => this.logout(redirectUrl)

    store.set('riotBar.ready', true)
    this.fixAccessibilityIssues()

    const menuItemClasses = [
      '#riotbar-center-content', // the entire bar for desktop
      '#riotbar-right-content' // right side logged submenu + mobile menu
    ].join(',')

    // add click events to navigation menu items
    $(menuItemClasses).on('click', (event) => {
      let target = $(event.target)

      if (!target.is('a[href]')) {
        target = target.parent()
      }

      if (!target.is('a[href]') || target.hasClass('riotbar-active-link')) {
        return event.preventDefault()
      }

      if (target.is('a[href="#leagues"]')) {
        event.preventDefault()
        store.set('leaguesMenu.isActive', true)
        return
      }

      // external link, let the browser open in a new tab
      if (target.attr('target') === '_blank') return

      // go to the new route
      const link = target.attr('href')

      $('.mobile-menu-close').trigger('click') // close menu on mobile

      if (
        link.includes('schedule')
        || link.includes('vods')
        || link.includes('news')
        || link.includes('gpr')
        || link === '/' || link === `/${locStr}` || link === ''
      ) {
        return
      }

      router.go(link, event)
    })

    // add click event to language switcher
    $('.riotbar-locale-switcher').on('click', (event) => {
      event.preventDefault()
      const targetLink = $(event.target)
        .closest('a')
        .attr('href')

      if (!targetLink) return
      const targetLocale = targetLink.replace('/', '')

      locale.set(targetLocale)
      window.location.reload()
    })
  }

  // Accessibility requirements
  // should be supported in RiotBar but it is not, we're doing it here
  fixAccessibilityIssues () {
    $('.second-logo a, .riotbar-explore-label a').attr(
      'aria-label',
      'LoL Esports'
    )

    $('.riotbar-logo').attr('aria-label', 'Riot Games')

    $('.lang-switch-trigger').attr(
      'aria-label',
      locale.translate('watch.languageSelection')
    )

    $('.riotbar-explore').attr(
      'aria-label',
      locale.translate('navigation.menu')
    )

    $('.sub-menu-close').attr(
      'aria-label',
      locale.translate('navigation.close')
    )
  }

  isRegionlessAccount () {
    return window.RiotBar?.account?.isRegionlessAccount() || false
  }

  setup () {
    const url = hosts[env]

    window.require([url]) // requirejs
  }

  getRegionCode () {
    return util.getCookie(regionCookieKey)
  }

  getPuuid () {
    return window.RiotBar?.account?.getAuthState().sub || null
  }

  logout (returnUrl) {
    const redirectUrl = window.encodeURIComponent(
      returnUrl || window.location.href
    )
    const url = `${window.RiotBar.account.getLogoutUrl()}?uri=${redirectUrl}`

    analytics.trackEvent('Logout')
    analytics.reset()
    window.location.replace(url)
  }

  login (returnUrl) {
    const redirectUrl = window.encodeURIComponent(
      returnUrl || window.location.href
    )
    const uiLocales = locale.get()
    const url = `${window.RiotBar?.account?.getLoginUrl()}?uri=${redirectUrl}&ui_locales=${uiLocales}`

    analytics.trackEvent('login_started')

    window.location.replace(url)
  }

  isLoggedIn () {
    return window.RiotBar?.account?.getAuthState()?.isAuthenticated
  }

  hasLocalIdHint () {
    return !!util.getCookie(xssoIdHintCookieKey)
  }

  getAuthState () {
    if (!this.isLoggedIn()) {
      return null
    }

    return window.RiotBar.account.getAuthState()
  }
}

export default new RiotBar()
