import config from 'Common/Config/Config'
import locale from 'Common/Locale/Locale'
import serviceUtil from 'Common/Service/Util/ServiceUtil'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import util from 'Common/Util/Util'
import { store } from 'lib'

const hosts = {
  local: '/mock-service/settings',
  dev: 'https://dev-settings.lolesports.com/esp/v1',
  test: 'https://test-settings.lolesports.com/esp/v1',
  stage: 'https://stage-settings.lolesports.com/esp/v1',
  prod: 'https://settings.lolesports.com/esp/v1'
}

const baseUrl = hosts[config.env]
const authorizationHeader = {
  credentials: 'include',
  headers: {
    Authorization: 'Cookie __Secure-access_token'
  }
}

// Default settings are used for users who are not currently logged in, or if
// the request to fetch settings has failed.  The latter ensures that a page
// will still load if the fetch request fails, even if it may not be the user's
// desired settings.
const defaultSettings = {
  watchSettings: {
    locale: '',
    jumpToStart: 'always',
    statsLayout: 'theater',
    defaultVideoProvider: 'any'
  },
  notificationsSettings: {
    watch: 'enabled',
    client: 'disabled',
    plus: 'disabled'
  },
  topics: {
    teams: [],
    leagues: []
  }
}

class Settings {
  constructor () {
    this.hl = locale.get()
  }

  setup () {
    if (!riotBar.isLoggedIn()) return store.set('settings', defaultSettings)

    store.onChange(
      'riotBar.authChecked',
      this.listener = () => {
        this.fetchPreferences().then(() =>
          store.removeListener('riotBar.authChecked', this.listener)
        )
      }
    )
  }

  getDefaultSettings () {
    return defaultSettings
  }

  fetchPreferences () {
    const puuid
      = config.env === config.envs.LOCAL
        ? 'some-test-puuid-for-local'
        : riotBar.getPuuid()
    const url = baseUrl + '/preferences/lol/' + puuid

    if (!puuid) {
      store.set('settings', defaultSettings)
      return
    }

    return serviceUtil
      .fetch(url, authorizationHeader)
      .then((prefs) => {
        if (!prefs || Object.keys(prefs.watchSettings).length === 0) {
          // Use default settings when the server returns no settings at all,
          // or when the server gives an empty settings response.
          // e.g. when a user logs in for the first time and gets a 204 status.
          return store.set('settings', defaultSettings)
        }
        const mergedPrefs = util.deepMerge(defaultSettings, prefs)

        store.set('settings', mergedPrefs)
      })
      .catch(() => store.set('settings', defaultSettings))
  }

  updatePreferences (prefs) {
    const puuid = riotBar.getPuuid()
    const url = baseUrl + '/preferences/lol/' + puuid

    const options = {
      method: 'POST',
      ...authorizationHeader,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(prefs)
    }

    return serviceUtil.fetch(url, options).then(() => {
      store.set('settings', prefs)
    })
  }
}

export default new Settings()
