/* eslint max-lines: ["error", {"max": 250, "skipBlankLines": true, "skipComments": true}] */
import { Logger, store } from 'lib'
import config from 'Common/Config/Config'
import locale from 'Common/Locale/Locale'
import semver from 'semver'

const roles = ['top', 'jungle', 'mid', 'bottom', 'support']
const abilityKeys = ['Q', 'W', 'E', 'R']
const patchErrorMessage = 'You must call dataDragon.setup(patchVersion) first'
const localeErrorMessage = 'No locale was resolved for DataDragon'
const defaultRole = roles[0] // if no role is selected, default to this
const clearTagsRe = /<(?!br).*?>/g

// only used if not able to fetch versions file and if game server not passed to setup
const fallbackPatch = '9.8.1'

const envHosts = {
  local: 'https://ddragon.leagueoflegends.com',
  dev: 'https://ddragon.leagueoflegends.com',
  test: 'https://ddragon.leagueoflegends.com',
  stage: 'https://ddragon.leagueoflegends.com',
  prod: 'https://ddragon.leagueoflegends.com'
}

class DataDragon {
  constructor () {
    this.patch = null
    this.log = new Logger(this.constructor.name)
    this.caches = {
      version: [],
      champion: {},
      itemList: undefined,
      runeList: undefined
    }
    this.locale = locale
  }

  setup (gameServerVersion) {
    if (this.caches.version && this.caches.version.length) {
      const matchedVersion = this.getMatchingVersion(gameServerVersion)

      this.initializeVariables(matchedVersion, gameServerVersion)
    }
    else {
      this.fetchVersions(gameServerVersion)
    }
  }

  fetchVersions (gameServerVersion) {
    const versionsUrl = `${envHosts[config.env]}/api/versions.json`

    return this.fetch(versionsUrl)
      .then((versions) => {
        this.caches.version = versions
        const matchedVersion = this.getMatchingVersion(gameServerVersion)

        this.initializeVariables(matchedVersion)
      })
      .catch((error) => {
        this.log.error('Unable to fetch data dragon versions file', error)

        // still want data dragon to function if we cannot fetch versions explicitly
        if (gameServerVersion) {
          const fallbackVersion = this.getFallbackVersion(gameServerVersion)

          this.initializeVariables(fallbackVersion)
        }
        else {
          this.log.error('Defaulting to hardcoded patch', fallbackPatch)
          this.initializeVariables(fallbackPatch)
        }
      })
  }

  initializeVariables (matchedVersion) {
    // TODO: reset caches if this.patch exists already and is different from matchedVersion
    this.patch = matchedVersion
    this.log.info('Using DataDragon version', this.patch)
    this.baseUrl = this.getBaseUrl()
    store.set('dataDragon.setup', true)
  }

  getFallbackVersion (gameServerVersion) {
    const normalizedVersion = this.getNormalizedGameServerVersion(
      gameServerVersion
    )

    return normalizedVersion + '.1'
  }

  getNormalizedGameServerVersion (gameServerVersion) {
    const [seasonNumber, patchNumber] = gameServerVersion.split('.')

    return seasonNumber + '.' + patchNumber
  }

  getMatchingVersion (gameServerVersion) {
    if (!gameServerVersion) {
      return this.caches.version[0]
    }

    const normalizedVersion = this.getNormalizedGameServerVersion(
      gameServerVersion
    )

    for (const index in this.caches.version) {
      if (this.caches.version[index].indexOf(normalizedVersion) === 0) {
        return this.caches.version[index]
      }
    }

    this.log.error('No version match, choosing', this.caches.version[0])
    return this.caches.version[0]
  }

  getBaseUrl () {
    const host = envHosts[config.env]

    return `${host}/cdn/${this.patch}`
  }

  getPatchlessBaseUrl () {
    const host = envHosts[config.env]

    return `${host}/cdn`
  }

  sanitizeDescription (description) {
    // remove all non-<br> HTML tags from description
    const sanitizedAbilityDescription = description.replace(clearTagsRe, '')

    // split description based on '<br>' strings
    const splitAbilityDescription = sanitizedAbilityDescription.split('<br>')

    // add back <br> as real HTML elements
    const descriptionElementsToRender = []

    splitAbilityDescription.map((abilityString) => {
      descriptionElementsToRender.push(abilityString)
      descriptionElementsToRender.push(<br/>)
    })

    return descriptionElementsToRender
  }

  fetch (url) {
    return window
      .fetch(url)
      .then((resp) => resp.json())
      .catch((error) => {
        throw error
      })
  }

  mapChampionId (id) {
    // Game server is returning 'FiddleSticks', but DD data is 'Fiddlesticks'
    return id === 'FiddleSticks' ? 'Fiddlesticks' : id
  }

  getRoles () {
    return roles
  }

  getDefaultRole () {
    return defaultRole
  }

  getAbilityKeys () {
    return abilityKeys
  }

  getPatchVersion () {
    return this.patch
  }

  getAbilityImage (image) {
    return `${this.baseUrl}/img/spell/${image}`
  }

  getPassiveAbilityImage (image) {
    return `${this.baseUrl}/img/passive/${image}`
  }

  getChampionImage (id) {
    if (this.patch === null) {
      throw Error(patchErrorMessage)
    }

    return `${this.baseUrl}/img/champion/${this.mapChampionId(id)}.png`
  }

  getMissionImage (image) {
    if (this.patch === null) {
      throw Error(patchErrorMessage)
    }

    return `${this.baseUrl}/img/mission/${image}`
  }

  getProfileIcon (id) {
    if (this.patch === null) {
      throw Error(patchErrorMessage)
    }

    return `${this.baseUrl}/img/profileicon/${id}.png`
  }

  getLocale (browserLocale = this.locale.get(), patchVersion = this.patch) {
    if (patchVersion === null) {
      throw Error(patchErrorMessage)
    }

    if (browserLocale === null) {
      throw Error(localeErrorMessage)
    }

    let newLocale = browserLocale

    // DD does not support all locales or uses different code(s)
    // docs: https://developer.riotgames.com/docs/lol#data-dragon_regions
    // ref: https://ddragon.leagueoflegends.com/cdn/languages.json

    // indonesian unsupported locale
    if (browserLocale.match(/id(-|_)ID/)) {
      newLocale = 'en-US'
    }

    // vietnamese locale uses different code before patch 13.x
    // older patches are only used by the Worlds 2022 VODs
    if (browserLocale.match(/vi(-|_)VN/)) {
      if (semver.lt(patchVersion, '13.0.0')) {
        newLocale = 'vn-VN'
      }
    }

    return newLocale.replace('-', '_') // DD uses en_US instead of en-US
  }

  getItemImage (id) {
    if (this.patch === null) {
      throw Error(patchErrorMessage)
    }

    return `${this.baseUrl}/img/item/${id}.png`
  }

  getRuneImage (path) {
    const baseUrl = this.getPatchlessBaseUrl()

    return `${baseUrl}/img/${path}`
  }

  fetchChampion (id) {
    if (this.patch === null) {
      throw Error(patchErrorMessage)
    }
    if (this.caches.champion[id]) {
      this.log.debug('Returning from cache', id)

      return new Promise((resolve) => resolve(this.caches.champion[id]))
    }

    const resolvedLocale = this.getLocale()
    const url
      = `${this.baseUrl}/data/${resolvedLocale}`
      + `/champion/${this.mapChampionId(id)}.json`

    return this.fetch(url)
      .then((json) => {
        this.log.debug('Saving in cache for', id)

        this.caches.champion[id] = json
        return json
      })
      .catch((error) => {
        throw error
      })
  }

  fetchItemList () {
    if (this.patch === null) {
      throw Error(patchErrorMessage)
    }

    if (this.caches.itemList) {
      return new Promise((resolve) => resolve(this.caches.itemList))
    }

    const resolvedLocale = this.getLocale()
    const url = `${this.baseUrl}/data/${resolvedLocale}/item.json`

    return this.fetch(url)
      .then((itemList) => {
        this.caches.itemList = itemList
        return itemList
      })
      .catch((error) => {
        throw error
      })
  }

  fetchRuneList () {
    if (this.patch === null) {
      throw Error(patchErrorMessage)
    }
    if (this.caches.runeList) {
      return new Promise((resolve) => resolve(this.caches.runeList))
    }

    const resolvedLocale = this.getLocale()
    const url = `${this.baseUrl}/data/${resolvedLocale}/runesReforged.json`

    return this.fetch(url)
      .then((runeList) => {
        this.caches.runeList = runeList
        return runeList
      })
      .catch((error) => {
        throw error
      })
  }
}

export default new DataDragon()
