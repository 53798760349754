import { $, render } from 'lib'
import device from 'Common/Device/Device'
import util from 'Common/Util/Util'

class ConfigPanel {
  setup (appVersion) {
    this.appVersion = appVersion
    this.addListeners()
  }

  addListeners () {
    document.addEventListener('keypress', (event) => {
      if (event.shiftKey && event.which === 67) {
        // shift + C key
        this.toggle()
      }
    })

    device.addSwipeListener(document, (direction) => {
      if (direction.topRightToBottomLeft) {
        this.toggle()
      }
    })
  }

  setEnvironment () {
    const value = $('#config-panel .environment select').val()

    if (value) {
      window.sessionStorage.setItem('env', value)
      window.location.reload()
    }
  }

  renderEnvironment () {
    return (
      <div class="environment">
        <h3>Environment</h3>
        <select onchange={ this.setEnvironment }>
          <option>select environment</option>
          { this.renderOptions(['local', 'dev', 'test', 'stage', 'prod']) }
        </select>
      </div>
    )
  }

  setLiveStatsEnvironment () {
    const value = $('#config-panel .livestats-api-environment select').val()

    if (value === 'off') {
      window.sessionStorage.removeItem('livestats-api-env')
    }
    else if (value) {
      window.sessionStorage.setItem('livestats-api-env', value)
    }
    window.location.reload()
  }

  renderLiveStatsEnvironment () {
    return (
      <div class="livestats-api-environment">
        <h3>LiveStats Environment</h3>
        <select onchange={ this.setLiveStatsEnvironment }>
          <option>select environment</option>
          { this.renderOptions(['off', 'dev', 'test', 'prod']) }
        </select>
      </div>
    )
  }

  setRewardsEnvironment () {
    const value = $('#config-panel .rewards-api-environment select').val()

    if (value === 'off') {
      window.sessionStorage.removeItem('rewards-api-env')
    }
    else if (value) {
      window.sessionStorage.setItem('rewards-api-env', value)
    }
    window.location.reload()
  }

  renderRewardsEnvironment () {
    return (
      <div class="rewards-api-environment">
        <h3>Rewards Environment</h3>
        <select onchange={ this.setRewardsEnvironment }>
          <option>select environment</option>
          { this.renderOptions(['off', 'dev', 'test', 'stage', 'prod']) }
        </select>
      </div>
    )
  }

  setBetaFlag () {
    const value = $('#config-panel .beta-flag select').val()

    if (value === 'off') {
      document.cookie = 'beta=; expires=Thu, 18 Dec 1970 12:00:00 UTC;'
    }
    else if (value) {
      document.cookie = 'beta=true; expires=Thu, 18 Dec 2020 12:00:00 UTC;'
    }
    window.location.reload()
  }

  renderBetaFlag () {
    return (
      <div class="beta-flag">
        <h3>Beta Flag</h3>
        <select onchange={ this.setBetaFlag }>
          <option>select flag</option>
          { this.renderOptions(['on', 'off']) }
        </select>
      </div>
    )
  }

  setLogging () {
    const value = $('#config-panel .logging select').val()

    if (value) {
      window.localStorage.setItem('log-level', value)
      window.location.reload()
    }
  }

  renderLogging () {
    return (
      <div class="logging">
        <h3>Logging</h3>
        <select onchange={ this.setLogging }>
          <option>select log level</option>
          { this.renderOptions(['off', 'debug', 'info', 'warn', 'error']) }
        </select>
      </div>
    )
  }

  renderVersion () {
    return <div class="version">v{ this.appVersion }</div>
  }

  renderOptions (list) {
    return list.map((item) => <option value={ item }>{ item }</option>)
  }

  toggle () {
    $('#config-panel').length ? this.remove() : this.append()
  }

  remove () {
    $('#config-panel').remove()
  }

  renderPuuid () {
    const idHint = window.decodeURIComponent(util.getCookie('id_hint'))
    const match = idHint ? idHint.match(/sub=([^&]*)/i) : null
    const puuid = match && match[1] ? match[1] : null

    if (!puuid) return

    return (
      <div class="puuid">
        <h3>PUUID</h3>
        <div class="value">{ puuid }</div>
      </div>
    )
  }

  sendNotification () {
    const type = $('#config-panel .notifications select').val()

    const dropFulfilledMessage = {
      resource: 'esports/v1/drop_fulfilled',
      i: '6d5c8ca5-2493-47a6-af80-0bee800cd87f',
      l: '17aef778-752c-11ea-bc55-0242ac130003',
      s: '/rewards/drops/spotify.png',
      p: '/rewards/drops/poro.jpg',
      k: 1,
      c: ['#3F48D8', '#8DE8F9', '#000000']
    }

    const rareDropFulfilledMessage = {
      resource: 'esports/v1/drop_fulfilled',
      i: '6d5c8ca5-2493-47a6-af80-0bee800cd87f',
      l: '17aef778-752c-11ea-bc55-0242ac130003',
      s: '/rewards/drops/spotify.png',
      p: '/rewards/drops/poro.jpg',
      k: 1,
      c: ['#AB2D1D', '#FF9385', '#000000']
    }

    const matchStartedMessage = {
      resource: 'esports/v1/match_started',
      match: {
        id: '123',
        leagueGuid: '98767991299243165',
        team1: {
          id: '98767991877340524',
          name: 'Cloud9',
          code: 'C9',
          image:
            'https://am.i.leagueoflegends.com/image?resize=70:&f=https%3A%2F%2Flolstatic-a.akamaihd.net%2Fesports-assets%2Fproduction%2Fteam%2Fcloud9-gnd9b0gn.png',
          slug: 'cloud9'
        },
        team2: {
          id: '98767991302996019',
          name: 'TSM',
          code: 'TSM',
          image:
            'https://am-a.akamaihd.net/image/?resize=70:&f=https%3A%2F%2Flolstatic-a.akamaihd.net%2Fesports-assets%2Fproduction%2Fteam%2Ftsm-fac17n8j.png',
          slug: 'tsm'
        }
      }
    }

    const broadcastStartedMessage = {
      resource: 'esports/v1/broadcast_started',
      broadcast: {
        id: '456',
        league: {
          id: '98767991310872058',
          slug: 'lck',
          name: 'LCK',
          image:
            'http://static.lolesports.com/leagues/1592516205122_LCK-01-FullonDark.png'
        }
      }
    }

    const broadcastChannel = new window.BroadcastChannel('notifications')

    if (type === 'DROP_FULFILLED') {
      broadcastChannel.postMessage(dropFulfilledMessage)
    }
    else if (type === 'RARE_DROP_FULFILLED') {
      broadcastChannel.postMessage(rareDropFulfilledMessage)
    }
    else if (type === 'MATCH_STARTED') {
      broadcastChannel.postMessage(matchStartedMessage)
    }
    else if (type === 'BROADCAST_STARTED') {
      broadcastChannel.postMessage(broadcastStartedMessage)
    }
  }

  renderNotifications () {
    return (
      <div class="notifications">
        <h3>Notifications</h3>
        <select onchange={ this.sendNotification }>
          <option>select message</option>
          { this.renderOptions([
            'DROP_FULFILLED',
            'RARE_DROP_FULFILLED',
            'MATCH_STARTED',
            'BROADCAST_STARTED'
          ]) }
        </select>
      </div>
    )
  }

  append () {
    const configPanel = (
      <div id="config-panel">
        <h1>Config Panel</h1>
        { this.renderVersion() }
        { this.renderEnvironment() }
        { this.renderRewardsEnvironment() }
        { this.renderLiveStatsEnvironment() }
        { this.renderBetaFlag() }
        { this.renderLogging() }
        { this.renderNotifications() }
        { this.renderPuuid() }
      </div>
    )

    render(configPanel, document.body)
  }
}

export default new ConfigPanel()
