import { $ } from 'lib'

const appName = 'EWP Web' // TODO: change to EGW, inform insights team

class Qualaroo {
  setup () {
    window._kiq = window._kiq || []
    // Prevent Qualaroo from automatically loading a survey
    window._kiq.push(['disableAuto'])
    // Identify this as the web front end
    window._kiq.push([
      'set',
      {
        app_name: appName
      }
    ])

    this.addListeners()
  }

  addListeners () {
    // This is an SPA, which requires some custom handling on our part for
    // Qualaroo to work correctly. Usually when the qualaroo script is loaded,
    // it considers that to indicate a URL change and relies on that event to
    // look for potential surveys to show. However this script is only loaded
    // once. So we include the disableAuto parameter and on navigation push the
    // correct events to _kiq to imitate the same behavior.
    $(document).on('router.navigation.go', () => {
      // Hide any current running survey
      window._kiq.push(['stopNudge'])
      // Try and display a survey that matches the targeting options. Same as
      // what happens when qualaroo JS loads without disableAuto.
      window._kiq.push(['selectNudge'])
    })
  }
}

export default new Qualaroo()
