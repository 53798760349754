import { Logger } from 'lib'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import locale from 'Common/Locale/Locale'
import config from 'Common/Config/Config'
import serviceUtil from 'Common/Service/Util/ServiceUtil'

const hosts = {
  local: '/mock-service/eras-passes/v1',
  dev: 'https://dev-raptor.rewards.lolesports.com/v1',
  test: 'https://qa-raptor.rewards.lolesports.com/v1',
  stage: 'https://stage-raptor.rewards.lolesports.com/v1',
  prod: 'https://raptor.rewards.lolesports.com/v1'
}

const env = window.sessionStorage.getItem('rewards-api-env') || config.env
const baseUrl = hosts[env]

class RewardsTeamPersistence {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  fetchPassInfo () {
    if (!riotBar.isLoggedIn()) {
      return Promise.resolve({ loggedOut: true })
    }

    const url = baseUrl + '/passes/info?locale=' + locale.get(true)

    return serviceUtil
      .fetch(url, {
        credentials: 'include'
      })
      .then((json) => {
        if (!json.actionable) {
          throw Error(locale.translate('message.invalidResponse'))
        }
        return json
      })
  }

  selectTeamPass (id) {
    if (!riotBar.isLoggedIn()) {
      return Promise.resolve({ loggedOut: true })
    }

    const url = baseUrl + '/passes/team/' + id

    return serviceUtil.fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      }
    })
  }
}

export default new RewardsTeamPersistence()
