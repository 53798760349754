import { Logger } from 'lib'
import config from 'Common/Config/Config'
import locale from 'Common/Locale/Locale'
import serviceUtil from 'Common/Service/Util/ServiceUtil'
import util from 'Common/Util/Util'

const publicKey = 'bltad9188aa9a70543a'
const deliveryTokens = {
/////////////////////////////
  preview: 'cs2da3d5e32d2bbe63d742b243',
////////////
  production: 'cs9bf74a8cc357da4224f2b444'
}

const environment = {
  local: 'preview',
  dev: 'preview',
  test: 'preview',
  stage: 'preview',
  prod: 'production'
}

const contentstackEnv = environment[config.env]
const baseUrl = 'https://cdn.contentstack.io/v3/content_types'

const headers = {
  headers: {
    api_key: publicKey,
    access_token: deliveryTokens[contentstackEnv]
  }
}

const homepageFieldsForReferenceType = {
  all: ['title', '_content_type_uid'],
  articles: ['intro', 'header_image', 'external_link'],
  youtube_videos: ['thumbnail_high', 'thumbnail_low', 'duration'],
  twitch_clips: ['thumbnail_url', 'twitch_id'],
  sponsors: ['url', 'block_image', 'banner_image']
}

const defaultLocale = 'en-us'

class Contentstack {
  constructor () {
    this.hl = locale.get(false, true)
    this.log = new Logger(this.constructor.name)
  }

  getUrl (contentType, uid, extraParams = [], extraPaths = []) {
    if (config.env === 'local') {
      const hasSkipParam = extraParams.find(
        (param) => param.key === 'skip' && param.value > 0
      )
      const mockFileType = uid ? `${contentType}_single` : contentType
      const mockFile = hasSkipParam ? `${mockFileType}_2` : mockFileType

      return '/mock-service/contentstack/' + mockFile
    }

    return (
      `${baseUrl}/${contentType}/entries`
      + (uid ? `/${uid}` : '')
      + (extraPaths.length ? '/' + extraPaths.join('/') : '')
      + `?environment=${contentstackEnv}`
      + `&bustcache=bustcache` // TODO: remove when contentstack has resolved their caching issues
      + extraParams.map((param) => `&${param.key}=${param.value}`).join('')
    )
  }

  localeParam (locale) {
    return { key: 'locale', value: locale || this.hl }
  }

  // Instructs the response to include the requested entries in thier fallback
  // language if translations for those entries don't exist for the supplied
  // locale. If no data is available in the fallback language, data from the
  // master language will be returned.
  fallbackParam () {
    return { key: 'include_fallback', value: true }
  }

  // Asks for a count of the total number of entries for the requested content
  // type. Since Contentstack only returns 100 entries per request, this count
  // must be used in conjunction with the 'skip' parameter to fetch any
  // additional entries past 100.
  includeCount () {
    return { key: 'include_count', value: 'true' }
  }

  skip (skipVal) {
    return { key: 'skip', value: skipVal }
  }

  // Asks for all fields from a reference instead of just the reference uid
  include (references) {
    return references.map((reference) => ({
      key: 'include[]',
      value: `${reference}`
    }))
  }

  // Specifies certain fields to return from a reference instead of all fields
  // NOTE: must include one parameter per field you are requesting
  only (fields) {
    const parameters = []

    Object.keys(fields).map((reference) => {
      fields[reference].map((field) => {
        parameters.push({
          key: `only[${reference}]`,
          value: field
        })
      })
    })

    return parameters
  }

  // Specifies certain fields to return from a content type
  // NOTE: refers to top-level fields of the content types' schema, NOT those of
  // reference fields
  onlyBase (fields) {
    const parameters = []

    fields.map((field) => {
      parameters.push({
        key: `only[BASE][]`,
        value: field
      })
    })

    return parameters
  }

  // Generates extra parameters for requests fetching all entries for the given
  // content type
  allEntriesParams (contentType) {
    const contentTypeFields = [
      ...homepageFieldsForReferenceType[contentType],
      'content_filters'
    ]

    if (contentType === 'youtube_videos') {
      contentTypeFields.push('esports_locale')
    }

    return [
      this.localeParam(),
      this.includeCount(),
      ...this.onlyBase(homepageFieldsForReferenceType.all),
      ...this.onlyBase(contentTypeFields),
      ...this.onlyBase(['created_at']),
      ...this.include(['content_filters']),
      ...this.only({ content_filters: ['title'] })
    ]
  }

  fetchHomepage () {
    const params = [
      this.localeParam(),
      ...this.include(['hero.reference', 'content_list', 'sponsors']),
      ...this.only({
        'hero.reference': [
          ...homepageFieldsForReferenceType.all,
          ...homepageFieldsForReferenceType.articles
        ],
        'content_list': [
          ...homepageFieldsForReferenceType.all,
          ...homepageFieldsForReferenceType.articles,
          ...homepageFieldsForReferenceType.youtube_videos,
          ...homepageFieldsForReferenceType.twitch_clips
        ],
        'sponsors': [
          ...homepageFieldsForReferenceType.all,
          ...homepageFieldsForReferenceType.sponsors
        ]
      })
    ]

    const url = this.getUrl('homepage', undefined, params)

    return serviceUtil.fetch(url, headers).then((json) => {
      const data = util.getDeepValue(json, 'entries.0')

      if (!data) {
        throw Error(locale.translate('message.invalidResponse'))
      }

      // References are added to the bottom on Contentstack. Reverse the list
      // so the most recent data is located near index 0. Filter out bad data
      const filteredReversedContentList = data.content_list
        .filter((article) => !!article.title)
        .reverse()

      data.content_list = filteredReversedContentList

      return data
    })
  }

  fetchVideoDetail (uid) {
    const url = this.getUrl('youtube_videos', uid, [this.localeParam()])

    return serviceUtil.fetch(url, headers).then((json) => {
      const data = util.getDeepValue(json, 'entry')

      if (!data) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return data
    })
  }

  fetchArticleLocales (uid) {
    const url = this.getUrl('articles', uid, [], ['locales'])

    return serviceUtil.fetch(url, headers).then((json) => {
      const data = util.getDeepValue(json, 'locales')

      if (!data) {
        throw Error(locale.translate('message.invalidResponse'))
      }

      return data
    })
  }

  refetchArticleDetail (uid) {
    return this.fetchArticleLocales(uid).then((locales) => {
      const localeObject = locales.find(
        (locale) => locale.code !== defaultLocale && locale.localized === true
      )

      if (localeObject) {
        this.log.info(
          `Found localization for ${localeObject.code}. Refetching with that locale.`
        )
        return this.fetchArticleDetail(uid, localeObject.code)
      }
      else {
        throw Error(locale.translate('message.invalidResponse'))
      }
    })
  }

  // 1. First fetch article in user's site locale
  // 2. If that fails, fetch article in the default locale (en-us)
  // 3. If that fails, fetch all localizations for the article and
  //    return first article
  fetchArticleDetail (uid, forcedLocale) {
    const params = [
      this.localeParam(forcedLocale),
      ...this.include(['league', 'author'])
    ]

    const url = this.getUrl('articles', uid, params)

    return serviceUtil
      .fetch(url, headers)
      .then((json) => {
        const data = util.getDeepValue(json, 'entry')

        if (!data) {
          throw Error(locale.translate('message.invalidResponse'))
        }
        return data
      })
      .catch(() => {
        if (!forcedLocale) {
          this.log.warn(
            `Article ${uid} not found in locale ${this.hl}. Defaulting to ${defaultLocale}.`
          )
          return this.fetchArticleDetail(uid, defaultLocale)
        }
        else if (forcedLocale === defaultLocale) {
          this.log.warn(
            `Article ${uid} not found in locale ${defaultLocale}. Fetching all locales for article.`
          )
          return this.refetchArticleDetail(uid)
        }
        else {
          throw Error(locale.translate('message.invalidResponse'))
        }
      })
  }

  fetchAllContentEntries (contentType, fetchedCount) {
    const params = [...this.allEntriesParams(contentType)]

    // add 'skip' param for subsequent requests
    if (fetchedCount >= 1) params.push(this.skip(fetchedCount))

    const url = this.getUrl(contentType, false, params)

    return serviceUtil.fetch(url, headers).then((json) => {
      if (!json) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return json
    })
  }

  fetchFilterGroups () {
    const params = [
      ...this.include(['content_filters']),
      ...this.onlyBase(['title', 'content_filters']),
      ...this.only({ content_filters: ['title'] }),
      this.localeParam()
    ]
    const url = this.getUrl('filter_groups', false, params)

    return serviceUtil.fetch(url, headers).then((json) => {
      const data = util.getDeepValue(json, 'entries')

      if (!data) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return data
    })
  }

  fetchLeaguesSubnavItems () {
    const params = [
      ...this.include(['league']),
      ...this.onlyBase(['league', 'links', 'order', 'home_locales']),
      ...this.only({ league: ['title'] }),
      this.localeParam(),
      this.fallbackParam()
    ]
    const url = this.getUrl('leagues_subnav_items', false, params)

    return serviceUtil.fetch(url, headers).then((json) => {
      const data = util.getDeepValue(json, 'entries')

      if (!data) {
        throw Error(locale.translate('message.invalidResponse'))
      }
      return data
    })
  }
}

export default new Contentstack()
