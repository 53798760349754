import { Logger } from 'lib'
import locale from 'Common/Locale/Locale'

const defaultFetchOptions = {
  mode: 'cors',
  credentials: 'same-origin'
}

const slowRequestTime = 500 // millis

class ServiceUtil {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  toParam (array) {
    // transform the array object into a comma separeted string
    // encode into a valid url param like %5B98110144877912983%2C%2098110144882005070%5D
    return window.encodeURIComponent('[' + array.join(',') + ']')
  }

  toGraphQLParam (array) {
    // transform the array object into a comma separeted string
    // encode into a valid url param like 1,2,3
    return window.encodeURIComponent(array.join(','))
  }

  handleResponseNotOk (response) {
    const contentType = response.headers.get('content-type')

    if (contentType.indexOf('application/json') > -1) {
      return response.json().then((json) => {
        throw Error(JSON.stringify(json))
      })
    }
    else if (contentType.indexOf('text/plain') > -1) {
      return response.text().then((text) => {
        throw Error(text)
      })
    }

    throw Error('Invalid content-type: ' + contentType)
  }

  fetch (url, options) {
    // TODO: handle request timeout
    const startRequestTime = Date.now()
    const fetchOptions = options
      ? Object.assign({}, defaultFetchOptions, options)
      : defaultFetchOptions

    return window
      .fetch(url, fetchOptions)
      .then((response) => {
        const finalRequestTime = Date.now() - startRequestTime

        if (finalRequestTime >= slowRequestTime) {
          this.log.warn('Slow request took', finalRequestTime, 'ms for', url)
        }

        if (!response.ok) return this.handleResponseNotOk(response)

        return response.status === 204 ? '' : response.json() // invalid JSON will throw and be caught below
      })
      .catch((error) => {
        // besides errors thrown above, DNS resolution is also caught here
        this.log.error('Fetch error:', error.message || error, 'url:', url) // print the real error
        throw Error(locale.translate('message.invalidResponse')) // re-throw translated error
      })
  }

  fetchWithRetry (
    url,
    options,
    getWaitTimeMilliseconds = () => 1000,
    numberOfRetries = 10
  ) {
    return this.fetch(url, options).catch((e) => {
      if (numberOfRetries === 1) {
        throw e
      }

      return new Promise((resolve) =>
        setTimeout(resolve, getWaitTimeMilliseconds())
      ).then(() =>
        this.fetchWithRetry(
          url,
          options,
          getWaitTimeMilliseconds,
          numberOfRetries - 1
        )
      )
    })
  }

  // TODO: This is per component - we should make this work on multiple components
  // by using video player timestamp as our 'now' time an storing that as our failed time
  shouldRetry (lastFetchAt, interval = 5000) {
    if (Date.now() - lastFetchAt > interval) {
      // If there is no error OR there is an error and our time difference works, try to retry
      return true
    }

    return false
  }
}

export default new ServiceUtil()
