import { Logger } from 'lib'
import config from 'Common/Config/Config'
import mixpanel from 'mixpanel-browser'
import {
  removeCookieValue,
  setCookieValue,
  getCookieValue
} from '../../Storage/Storage'

const PROJECT_TOKEN = '69f44b1b1f84407d54afcaf9589a0bae'

const mixpanelAllowedCookieName = '_rg_ana_app_can_track'

class Analytics {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  checkEligibility () {
    // nuke the local cookie, to ensure we can still write it and read it back
    removeCookieValue(mixpanelAllowedCookieName)

    // try to set the cookie and see if we can read it back
    setCookieValue(mixpanelAllowedCookieName, 'true', undefined)
    if (getCookieValue(mixpanelAllowedCookieName)) {
      return true
    }

    return false
  }

  initialize () {
    if (!this.checkEligibility()) {
      return
    }

    if (!mixpanel) {
      this.log.warn('Unable to initialize')
      return
    }

    mixpanel.init(PROJECT_TOKEN, {
      debug: config.isNonProdEnvironment(),
      persistence: 'localStorage'
    })

    mixpanel.people.union({ site: 'lol' })
    mixpanel.register({ site: 'lol' })
  }

  identify (id, attributes) {
    if (!this.checkEligibility()) {
      return
    }

    if (!mixpanel) {
      this.log.warn('Unable to identify')
      return
    }

    mixpanel.identify(id)

    if (attributes) {
      mixpanel.people.set(attributes)
    }
  }

  trackEvent (eventName, attributes) {
    if (!this.checkEligibility()) {
      return
    }

    if (!mixpanel) {
      this.log.warn('Unable to track')
      return
    }

    mixpanel.track(eventName, attributes)
  }

  trackPageEvent (pageName) {
    if (!this.checkEligibility()) {
      return
    }

    if (!mixpanel) {
      this.log.warn('Unable to track')
      return
    }

    mixpanel.track_pageview({ page: pageName })
  }

  reset () {
    if (!this.checkEligibility()) {
      return
    }

    if (!mixpanel) {
      this.log.warn('Unable to reset')
      return
    }

    mixpanel.reset()
  }
}

export default new Analytics()
