// regexs for getTimeParts
const lrmRE = /\u200E/g
const hourMinutesRE = /\d?\d(:|\.)\d\d/
const timeRE = /:|\./

class Util {
  isSameTime (timestamp1, timestamp2) {
    // find out if two timestamps pertain to the same time (their year, month, day, hour and minute are the same)
    const date1 = new Date(Number(timestamp1)).setSeconds(0, 0)
    const date2 = new Date(Number(timestamp2)).setSeconds(0, 0)

    return date1 === date2
  }

  isSameDay (timestamp1, timestamp2) {
    // find out if two timestamps pertain to the same day (their year, month and day are the same)
    const date1 = new Date(Number(timestamp1)).setHours(0, 0, 0, 0)
    const date2 = new Date(Number(timestamp2)).setHours(0, 0, 0, 0)

    return date1 === date2
  }

  // TODO: move to Device.isTouch()
  isTouchDevice () {
    return 'ontouchstart' in window
  }

  isIE () {
    const ua = window.navigator.userAgent
    // IE 10 or older
    const msie = ua.indexOf('MSIE ')
    // IE 11
    const trident = ua.indexOf('Trident/')

    return msie > 0 || trident > 0
  }

  toSlug (string) {
    // remove special characters and replace space for dash
    return string
      .replace(/[^\w\s]/gi, '')
      .replace(/ /g, '-')
      .toLowerCase()
  }

  toSlugUrl (string) {
    return string
      .replace(/[;/?:@=&"<>#%{}|\\^~[\]`]/g, '')
      .replace(/ /g, '-')
      .toLowerCase()
  }

  toUpperFirst (string) {
    return string.charAt(0).toLocaleUpperCase() + string.slice(1)
  }

  toMilliseconds (dateString) {
    return Date.parse(dateString)
  }

  toISOString (milliseconds) {
    return new Date(milliseconds).toISOString()
  }

  toMMSS (milliseconds) {
    return new Date(milliseconds).toISOString().slice(14, 19)
  }

  toHHMMSS (milliseconds) {
    return new Date(milliseconds).toISOString().slice(11, 19)
  }

  classNames (...args) {
    // remove undefined/false/null/empty string class names
    return args.filter((className) => className).join(' ')
  }

  // Sort a list of objects by the value of one of its properties.
  // Property value must be a number where 0 is the highest priority
  // (first item on the returned list) and higher numbers are lower
  // priority (bottom of the returned list)
  sortListByPropertyValue (list, prop) {
    return list.sort((item1, item2) => {
      if (item1[prop] < item2[prop]) return -1
      if (item1[prop] > item2[prop]) return 1
      return 0
    })
  }

  toUTC (milliseconds) {
    return milliseconds + new Date().getTimezoneOffset() * this.times.MINUTE
  }

  setCookie (name, value, expirationInDays = 0, path = '/') {
    const d = new Date()

    d.setTime(d.getTime() + expirationInDays * this.times.DAY)

    document.cookie = `${name}=${value};expires=${d.toUTCString()};path=${path}`
  }

  getCookie (name) {
    name += '='
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i]

      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1)
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length)
      }
    }
    return ''
  }

  // Gets the value of a deep nested object and returns undefined instead of
  // throwing an error if a property does not exist
  // Usage: getDeepValue(json, 'path.to.something.0.name')
  getDeepValue (nestedObj, path) {
    return path
      .split('.')
      .reduce(
        (obj, key) => obj && obj[key] !== 'undefined' ? obj[key] : undefined,
        nestedObj
      )
  }

  // We can't use the localized time string directly.
  // We need hour, minute and am/pm separately to style them differently
  getTimeParts (time) {
    // IE and Edge use LRM characters around dates. Read more: https://www.csgpro.com/blog/2016/08/a-bad-date-with-internet-explorer-11-trouble-with-new-unicode-characters-in-javascript-date-strings
    // Remove these characters to be able to use dates as numbers, not as strings
    // Also standardize dates by removing space between hour/minute am/pm as
    // locales like zh-TW don't have it
    time = time.replace(lrmRE, '').replace(' ', '')

    const hourMin = time.replace(lrmRE, '').match(hourMinutesRE)[0]
    const ampm = time.replace(hourMin, '') || undefined
    const [hour, min] = hourMin.split(timeRE)

    // locale using 12h clock and minute == 0: should return AM/PM
    // locale using 24h clock and minute == 0: should return minutes (00)
    const minute = Number(min) === 0 && ampm !== undefined ? undefined : min

    return { hour, minute, ampm }
  }

  /**
   * Creates a timer
   * @returns Function which returns milliseconds since startTimer was called
   *
   * Usage:
      ```
      const timer = util.startTimer()
      const timeEllapsed = timer()
      ```
   */
  startTimer () {
    const start = Date.now()

    return () => Date.now() - start
  }

  /**
   * Sometimes we must make sure localStorage is available and working as intended.
   * It might not be available in privacy related contexts like incognito/private modes
   * or when QUOTA_EXCEEDED_ERR is thrown.
   * More info: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
   */
  isLocalStorageAvailable () {
    try {
      const key = '__local_storage_key__'

      window.localStorage.setItem(key, key)
      window.localStorage.getItem(key)
      window.localStorage.removeItem(key)
      return true
    }
    catch (error) {
      return false
    }
  }

  /**
   * Searches a list of objects and returns the first that has the given `key`
   * and specified `value`. If no matching objects are found, returns
   * `undefined`
   *
   * Common usage: getObjectFromKey('slug', 'lcs', leagues)
   * @param {string} key - The field name to match
   * @param {any} value - The value to search for
   * @param {Array[object]} objects - The list of objects to search through
   */
  getObjectFromKey (key, value, objects) {
    return objects.find((object) => object[key] === value)
  }

  arraysContainSameItems (arrayA, arrayB) {
    if (arrayA.length !== arrayB.length) return false

    for (let i = 0; i < arrayA.length; i++) {
      if (arrayB.indexOf(arrayA[i]) === -1) return false
    }

    return true
  }

  get times () {
    return {
      DAY: 24 * 60 * 60 * 1000,
      DAYS: 24 * 60 * 60 * 1000,
      HOUR: 60 * 60 * 1000,
      HOURS: 60 * 60 * 1000,
      MINUTE: 60 * 1000,
      MINUTES: 60 * 1000,
      SECOND: 1000,
      SECONDS: 1000
    }
  }

  /**
   * Deeply merges objectB into objectA.
   */
  deepMerge (objectA, objectB) {
    const target = {}

    const merge = (obj) => {
      if (obj) {
        Object.keys(obj).map((prop) => {
          if (obj.hasOwnProperty(prop)) {
            if (
              obj[prop] !== null
              && typeof obj[prop] === 'object'
              && !Array.isArray(obj[prop])
            ) {
              target[prop] = this.deepMerge(target[prop], obj[prop])
            }
            else {
              target[prop] = obj[prop]
            }
          }
        })
      }
    }

    merge(objectA)
    merge(objectB)

    return target
  }

  getPixelRatio () {
    return window.devicePixelRatio
  }
}

export default new Util()
