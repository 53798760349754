import { store, WebSessionClient, Logger } from 'lib'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import config from 'Common/Config/Config'
import locale from 'Common/Locale/Locale'

const sessionHosts = {
  local: 'https://dev-account.rewards.lolesports.com/v1/session/',
  dev: 'https://dev-account.rewards.lolesports.com/v1/session/',
  test: 'https://qa-account.rewards.lolesports.com/v1/session/',
  stage: 'https://stage-account.rewards.lolesports.com/v1/session/',
  prod: 'https://account.rewards.lolesports.com/v1/session/'
}

const entitlementsHosts = {
  local: 'https://dev-entitlements.rewards.lolesports.com/v1/entitlements/',
  dev: 'https://dev-entitlements.rewards.lolesports.com/v1/entitlements/',
  test: 'https://qa-entitlements.rewards.lolesports.com/v1/entitlements/',
  stage: 'https://qa-entitlements.rewards.lolesports.com/v1/entitlements/', // TODO: intentionally pointing to qa as stage is broken
  prod: 'https://entitlements.rewards.lolesports.com/v1/entitlements/'
}

const env = window.sessionStorage.getItem('rewards-api-env') || config.env
const sessionServiceURL = sessionHosts[env]
const entitlementsServiceURL = entitlementsHosts[env]

const isProd = env === config.envs.PROD
const rsoClientID = isProd ? 'esports-rna-prod' : 'esports-rna-stage'

const webSessionClientConfig = {
  enabled: true,
  rsoClientID: rsoClientID,
  entitlementsServiceURL: entitlementsServiceURL,
  sessionServiceURL: sessionServiceURL,
  sessionExpiredAction: 'ignore',
  useRSOStage: !isProd,
  verboseLogging: !isProd,
  getLocale: locale.get
}

class WebSession {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  getWebSessionHostUrl () {
    return sessionServiceURL
  }

  setup () {
    store.onChange('riotBar.authChecked', () => this.start())
  }

  start () {
    // const authState = riotBar.getAuthState()

    // // https://gh.riotgames.com/web/riotbar/blob/master/src/plugins/account/account-utils.js
    // const userAccount = {
    //   summonerName: authState.name,
    //   region: authState.region,
    //   login: (returnUrl = window.location.href) => riotBar.login(returnUrl)
    // }

    // store.set('userAccount', userAccount)

    this.webSessionClient = new WebSessionClient(
      Object.assign({}, webSessionClientConfig, {
        logout: riotBar.logout,
        getRegion: () => riotBar.getAuthState()?.region
      })
    )
    this.webSessionClient.addSessionReadyListener(() => {
      store.set('webSessionClient.ready', true)
    })

    this.webSessionClient.addSessionStateListener((state) => {
      switch (state) {
        case 'created':
          this.log.debug('Web Session tokens have been created')
          break
        case 'refreshed':
          this.log.debug('Web Session tokens have been refreshed')
          break
        case 'expired':
          this.log.warn(
            'Web Session tokens have expired before being refreshed'
          )
          break
        case 'destroyed':
          this.log.debug('Web Session tokens have been destroyed')
          break
        case 'error':
          this.log.debug(
            'An error has occurred attempting to create or refresh the Web Session tokens'
          )
          break
      }
    })

    this.webSessionClient.start()
  }

  fetchPlayerEntitlements () {
    return this.webSessionClient.fetchPlayerEntitlements()
  }
}

export default new WebSession()
