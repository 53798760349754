import { Logger } from 'lib'
import locale from 'Common/Locale/Locale'
import riotBar from 'Common/Service/RiotBar/RiotBar'
import config from 'Common/Config/Config'
import serviceUtil from 'Common/Service/Util/ServiceUtil'

const hosts = {
  local: '/mock-service/eras-opting/v1',
  dev: 'https://dev-rex.rewards.lolesports.com/v1',
  test: 'https://qa-rex.rewards.lolesports.com/v1',
  stage: 'https://stage-rex.rewards.lolesports.com/v1',
  prod: 'https://rex.rewards.lolesports.com/v1'
}

const streamStartInfoEndpoints = {
  local: '/events/streamStartInfo',
  dev: '/streams/startInfo',
  test: '/streams/startInfo',
  stage: '/streams/startInfo',
  prod: '/streams/startInfo'
}

const optingStateUrls = {
  local: '/mock-service/eras-opting/v1/player/optedout',
  dev: 'https://dev-raptor.rewards.lolesports.com/v1/opting',
  test: 'https://qa-raptor.rewards.lolesports.com/v1/opting',
  stage: 'https://stage-raptor.rewards.lolesports.com/v1/opting',
  prod: 'https://raptor.rewards.lolesports.com/v1/opting'
}

const env = window.sessionStorage.getItem('rewards-api-env') || config.env
const baseUrl = hosts[env]
const streamStartInfoUrl = baseUrl + streamStartInfoEndpoints[env]
const optingStateUrl = optingStateUrls[env]

class RewardsOpting {
  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  fetchOptingState () {
    if (!riotBar.isLoggedIn()) {
      return Promise.resolve({ loggedOut: true })
    }

    if (this.fetchPromise) {
      return this.fetchPromise
    }

    this.fetchPromise = serviceUtil.fetch(optingStateUrl, {
      credentials: 'include',
      headers: {
        Authorization: 'Cookie __Secure-access_token'
      }
    })

    return this.fetchPromise
  }

  getHeartbeaterConfig () {
    // TODO heartbeatInvervalMs needs to be a dynamically configured property
    const isNonProdEnv = env !== config.envs.PROD
    const heartbeatIntervalOverride = parseInt(
      localStorage.getItem('heartbeat-interval'),
      10
    )
    const initialHeartbeatDelayOverride = parseInt(
      localStorage.getItem('initial-heartbeat-delay'),
      10
    )

    const { code, area } = locale.getGeoInfo()

    return {
      streamId: undefined,
      tournamentId: undefined,
      heartbeatUrl: baseUrl + '/events/watch',
      streamStartInfoUrl,
      optingStateUrl,
      verboseLog: isNonProdEnv,
      heartbeatIntervalMs:
        heartbeatIntervalOverride || (isNonProdEnv ? 10 : 60) * 1000,
      initialHeartbeatDelayMs: initialHeartbeatDelayOverride || 10000,
      geolocation: { code, area }
    }
  }

  optOut () {
    this.fetchPromise = undefined
    return serviceUtil.fetch(optingStateUrl, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': 'Cookie __Secure-access_token'
      }
    })
  }

  optIn () {
    this.fetchPromise = undefined
    return serviceUtil.fetch(optingStateUrl, {
      credentials: 'include',
      headers: {
        Authorization: 'Cookie __Secure-access_token'
      },
      method: 'DELETE'
    })
  }
}

export default new RewardsOpting()
