import { Logger } from 'lib'
import configPanel from 'Common/Config/Panel/ConfigPanel'
import util from 'Common/Util/Util'

const envs = {
  LOCAL: 'local',
  DEV: 'dev',
  TEST: 'test',
  STAGE: 'stage',
  PROD: 'prod'
}

const defaultEnv = envs.PROD

const envMap = {
  // local
  '127.0.0.1': envs.LOCAL,
  'localhost': envs.LOCAL,
  'local.lolesports.com': envs.LOCAL,
  // dev
  'dev.i.lolesports.com': envs.DEV,
  'dev.beta.lolesports.com': envs.DEV,
  'dev.legacy.lolesports.com': envs.DEV,
  'dev-watch.lolesports.com': envs.DEV,
  'dev.lolesports.com': envs.DEV,
  // test
  'test.i.lolesports.com': envs.TEST,
  'test.beta.lolesports.com': envs.TEST,
  'test.legacy.lolesports.com': envs.TEST,
  'test-watch.lolesports.com': envs.TEST,
  'test.lolesports.com': envs.TEST,
  // stage
  'stage.i.lolesports.com': envs.STAGE,
  'stage.beta.lolesports.com': envs.STAGE,
  'stage.legacy.lolesports.com': envs.STAGE,
  'stage-watch.lolesports.com': envs.STAGE,
  'stage.lolesports.com': envs.STAGE,
  // prod
  'beta.lolesports.com': envs.PROD,
  'legacy.lolesports.com': envs.PROD,
  'lolesports.com': envs.PROD,
  'www.lolesports.com': envs.PROD,
  'watch.lolesports.com': envs.PROD
}

class Config {
  constructor () {
    this.env = this.getEnvironment()
    this.appVersion = this.getAppVersion()

    this.log = new Logger(this.constructor.name)
    this.log.info('Using', this.env, 'environment version', this.appVersion)

    this.setConfigPanel()
  }

  get envs () {
    return envs
  }

  isNonProdEnvironment () {
    const host = window.location.hostname

    return envMap[host] !== envs.PROD
  }

  isNonProdHostOrBeta () {
    const host = window.location.hostname

    // matches an environment and that environment is non-production.
    // prod and stage are considered production for this purpose
    const isNonProdHost
      = envMap[host] !== undefined
      && envMap[host] !== envs.PROD
      && envMap[host] !== envs.STAGE
    const isBeta = !!util.getCookie('beta')

    return isNonProdHost || isBeta
  }

  setConfigPanel () {
    if (this.isNonProdHostOrBeta()) {
      configPanel.setup(this.appVersion)
    }
  }

  getAppVersion () {
    return window._appVersion
  }

  getEnvironment () {
    const savedEnv = window.sessionStorage.getItem('env')

    return savedEnv || envMap[window.location.hostname] || defaultEnv
  }
}

export default new Config()
