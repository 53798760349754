import { Logger } from 'lib'
import analytics from '../Service/Analytics/Analytics'

const XSSOStates = {
  Init: 'init',
  Set: 'set',
  Unchanged: 'unchanged',
  PollAborted: 'poll_aborted',
  LoginRequired: 'login_required',
  Limited: 'limited'
}

class XSSOStateTracker {
  mutPlayerState = undefined

  constructor () {
    this.log = new Logger(this.constructor.name)
  }

  getXSSO () {
    return window.xsso
  }

  initialize () {
    const xsso = this.getXSSO()

    if (xsso) {
      this.mutPlayerState = xsso.state()
      xsso.onEvent((type) => {
        if (type instanceof Error) {
          this.log.error(type)
          return
        }

        if (this.mutPlayerState === type) {
          return
        }

        if (
          (this.mutPlayerState === XSSOStates.Set
            || this.mutPlayerState === XSSOStates.Limited)
          && type === XSSOStates.LoginRequired
        ) {
          this.log.info('User session lost')
          analytics.trackEvent('xsso_state_change', {
            xssoState: type,
            previousState: this.mutPlayerState
          })
        }
        else if (type === XSSOStates.Limited) {
          this.log.info('User session limited')
          analytics.trackEvent('xsso_state_change', {
            xssoState: type,
            previousState: this.mutPlayerState
          })
        }

        this.mutPlayerState = type
      })
    }
  }
}

export default new XSSOStateTracker()
